import {
  GET_GYM_EXCLUSION_DATE,
  GET_GYM_EXCLUSION_DATE_ERROR
} from '../actions/gymActions';

const initState = {
  excludeDates: [],
}

const gymReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_GYM_EXCLUSION_DATE:
      if (action.payload) {
        return {
          ...state,
          excludeDates: action.payload.exclusion,
        };
      }
      break;

    case GET_GYM_EXCLUSION_DATE_ERROR:
      if (action.payload) {
        return {
          ...initState
        };
      }
      break;

    default:
      return state;
  }
}

export default gymReducer;