import React from 'react';
import LayoutWithSideBar from '../components/LayoutWithSideBar';

const ThankYou = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('order_id');
  const total = parseFloat(urlParams.get('total'));

  // if (total) {
  //   window.gtag('event', 'conversion', {
  //     'send_to': 'AW-724649409/GpAwCK_VqZICEMGLxdkC',
  //     'value': total,
  //     'currency': 'MYR',
  //     'transaction_id': ''
  //   });
  // }

  return (
    <LayoutWithSideBar>
      <div style={{ margin: "100px 0" }}>
        <h2>Enjoy your climb at Project Rock!</h2>
        <br />
        <h2>Please check your email to get your pass QR code for order &lt;{orderId}&gt;</h2>
      </div>
    </LayoutWithSideBar>
  )
}

export default ThankYou;