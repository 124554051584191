import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import { fetchWorkshops } from '../redux/actions/productActions';
import LayoutWithSideBar from '../components/LayoutWithSideBar';
import { getWorkshopClasses } from '../lib/product';
import productData from "../data/product.json";

const descLength = 175;

const Desc = ({ description = '' }) => {
  const [isCollapse, setIsCollapse] = useState(true);
  const pElement = useRef();

  const handleClick = () => {
    if (!isCollapse) {
      pElement.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
    setIsCollapse(isCollapse => !isCollapse);
  };

  return (
    <p ref={pElement} className="pass-description">
      {description.substring(0, isCollapse ? descLength : undefined)}
      {description.length > descLength &&
        <Fragment>
          {isCollapse && <span> ...</span>}
          <br />
          <span className="pass-read-more" onClick={handleClick}>
            {isCollapse ? 'Read more' : 'Read less'}
          </span>
        </Fragment>
      }
    </p>
  )
}

const Classes = () => {
  const dispatch = useDispatch();
  const workshops = useSelector(state => state.productData.workshops)
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    dispatch(fetchWorkshops());
  }, [dispatch]);

  let { gym } = useParams();
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];

  useEffect(() => {
    setClasses(
      getWorkshopClasses(
        workshops,
        path,
        gym.replace(/-/g, " ").toLowerCase()
      )
    );
  }, [workshops]);

  return (
    <LayoutWithSideBar>
      {classes && classes.map((cls, i) => {
        const style = {
          backgroundImage: 'url(\'/assets/images/workshops/' + gym + '-' + cls.toLowerCase() + '.png\')'
        };
        return (
          <Row key={i} className="space-pb-30">
            <Col md={5} sm={12}>
              <Link to={`/workshop/${gym}/${cls.replace(/\s+/g, '-').toLowerCase()}`}>
                <div className='pass-types' style={style} />
              </Link>
            </Col>
            <Col md={7} sm={12} className="space-mt-10">
              <Col xl={9} lg={10} md={12}>
                <Desc key={i} description={productData.workshopClasses[gym][cls.replace(/\s+/g, '-').toLowerCase()]} />
              </Col>
            </Col>
          </Row>
        )
      })}
    </LayoutWithSideBar>
  )
}

export default Classes;