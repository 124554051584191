import React, { useEffect } from 'react';
import { Container, Row, Col, Carousel, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { chunk, without, range } from 'lodash';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import { fetchPasses, fetchCategories } from '../redux/actions/productActions';

const OnlineTickets = ({ categories, fetchPasses, fetchCategories }) => {

  useEffect(() => {
    fetchCategories();
  }, [fetchPasses, fetchCategories]);

  let { gym } = useParams();

  const column = 2;
  const colWidth = 12 / column;

  let filteredCategories = [];
  if (gym === 'ikea-batu-kawan')
    filteredCategories = without(categories, 'Pre Sales');
  else if (gym === 'gurney-plaza')
    filteredCategories = without(categories, 'Pre Sales');

  const rows = chunk(filteredCategories, column);

  return (
    <Layout>
      <Container fluid className="layout online-ticket-box">
        <Row className="space-ptb-10">
          <Col lg={3} xl={3} className="d-none d-lg-block">
            <Sidebar />
          </Col>
          <Col lg={9} xl={6} className="online-ticket-icon">
            {rows && rows.map((cols, i) => {
              return (
                <Row key={i}>
                  {cols.map((col, j) => {
                    return (
                      <Col sm={12} md={colWidth} key={j}>
                        <div className="space-pb-30">
                          <Link to={`/online_ticket/${gym}/${col.toLowerCase().replace(/[ ]+/g, '-')}`}>
                            <div>
                              <Image src={`/assets/images/passes/${col}.jpg`} className="online-ticket-image" />
                            </div>
                          </Link>
                          {col === "School Group" &&
                            <span>For Kindergarten, and <br></br>Primary/Secondary Schools</span>
                          }
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              )
            })}
          </Col>
          <Col xl={3} className="d-none d-xl-block">
            <Carousel controls={false}>
              {range(1, 3).map((i) => {
                return (
                  <Carousel.Item key={i}>
                    <img
                      className="d-block w-100"
                      src={`${process.env.PUBLIC_URL}/assets/images/slider/SliderRight${i}.png`}
                      alt=""
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    categories: state.productData.categories
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPasses: () => {
      dispatch(fetchPasses());
    },
    fetchCategories: () => {
      dispatch(fetchCategories());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineTickets);