import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import Layout from './components/Layout';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import OnlineTickets from './pages/OnlineTickets';
import PassTypes from './pages/PassTypes';
import Tickets from './pages/Tickets';
import Classes from './pages/Classes';
import Workshops from './pages/Workshops';
import Competitions from './pages/Competitions';
import ChangeVisitDate from './pages/ChangeVisitDate';
import RenewMember from './pages/RenewMember';
import PaymentIncomplete from './pages/PaymentIncomplete';
import ThankYou from './pages/ThankYou';
import NotFound from './pages/404';

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <ScrollToTop />
        <Header />
        <Layout>
          <Switch>
            <Route exact path="/">
              <Redirect to="/online_ticket" />
            </Route>
            <Route exact path="/online_ticket" component={Home} />
            <Route path="/online_ticket/:gym" component={OnlineTickets} exact />
            <Route path="/online_ticket/:gym/:pass" component={PassTypes} exact />
            <Route path="/online_ticket/:gym/:pass/:type" component={Tickets} exact />
            <Route path="/competition/:eventid" component={Competitions} exact />
            <Route path="/workshop/" component={Home} exact />
            <Route path="/workshop/:gym" component={Classes} exact />
            <Route path="/workshop/:gym/:cls" component={Workshops} exact />
            <Route path="/change_date" component={ChangeVisitDate} exact />
            {/* <Route path="/renew_member" component={RenewMember} exact /> */}
            <Route path="/payment_incomplete" component={PaymentIncomplete} exact />
            <Route path="/thankyou" component={ThankYou} exact />
            <Route component={NotFound} />
          </Switch>
        </Layout>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
