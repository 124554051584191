import {
  SEARCH_MEMBER,
  SEARCH_MEMBER_ERROR,
  UPDATE_RENEWAL_CART,
  UPDATE_RENEWAL_CUSTOMER,
  RENEWAL_CHECKOUT_SUCCESS,
  RENEWAL_CHECKOUT_FAILED,
  RESTORE_RENEWAL_CART,
  VERIFY_RENEWAL_COUPON,
  CLEAR_RENEWAL_COUPON
} from '../actions/memberActions';

const initState = {
  member: null,
  cart: {
    origin: "ONLINE",
    customer: {},
    items: [],
    num_items: 0,
    subtotal: 0,
    retry_path: null
  },
  payment: null,
  error: ""
}

const memberReducer = (state = initState, action) => {
  switch (action.type) {
    case SEARCH_MEMBER:
      if (action.payload) {
        return {
          ...state,
          member: action.payload,
          error: ""
        };
      }
      break;

    case SEARCH_MEMBER_ERROR:
      if (action.payload) {
        return {
          ...state,
          member: null,
          error: action.payload
        };
      }
      break;

    case UPDATE_RENEWAL_CART:
      if (action.payload) {
        return {
          ...state,
          cart: {
            ...state.cart,
            items: [
              ...action.payload.items
            ],
            num_items: action.payload.num_items,
            subtotal: action.payload.subtotal,
            retry_path: action.payload.retry_path
          },
          error: ""
        };
      }
      break;

    case UPDATE_RENEWAL_CUSTOMER:
      if (action.payload) {
        return {
          ...state,
          cart: {
            ...state.cart,
            customer: action.payload
          }
        };
      }
      break;

    case RENEWAL_CHECKOUT_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          payment: action.payload
        };
      }
      break;

    case RENEWAL_CHECKOUT_FAILED:
      if (action.payload) {
        return {
          ...state,
          payment: action.payload
        };
      } else
        return { ...initState };

    case RESTORE_RENEWAL_CART:
      if (action.payload) {
        return {
          ...initState,
          cart: {
            ...action.payload
          }
        };
      }
      break;

    case VERIFY_RENEWAL_COUPON:
      return {
        ...state,
        cart: {
          ...state.cart,
          coupon: action.payload
        }
      };

    case CLEAR_RENEWAL_COUPON:
      return {
        ...state,
        cart: {
          ...state.cart,
          coupon: null
        }
      }

    default:
      return state;
  }
}

export default memberReducer;