import axios from 'axios';

export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const ONLINE_CHECKOUT_SUCCESS = "ONLINE_CHECKOUT_SUCCESS";
export const ONLINE_CHECKOUT_FAILED = "ONLINE_CHECKOUT_FAILED";
export const RESTORE_CART = "RESTORE_CART";
export const VERIFY_COUPON = "VERIFY_COUPON";
export const CLEAR_COUPON = "CLEAR_COUPON";

export const updateCart = (items, ticketCount, subTotal, retryPath) => {
  return dispatch => {
    dispatch({
      type: UPDATE_CART,
      payload: {
        items: [...items],
        num_items: ticketCount,
        subtotal: subTotal,
        retry_path: retryPath
      }
    });
  }
};

export const onlineCheckout = (customer) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_CUSTOMER,
      payload: customer
    });

    const cart = getState().orderData.cart;

    window.sessionStorage.setItem('cart', JSON.stringify(cart));

    axios.post('/v1/online_checkout', cart)
      .then((resp) => {
        console.log(resp)
        dispatch({
          type: ONLINE_CHECKOUT_SUCCESS,
          payload: resp.data
        });
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: ONLINE_CHECKOUT_FAILED,
          payload: {
            error: 'Online checkout failed!',
            id: new Date().valueOf() // use for state to refresh only
          }
        });
      });
  }
};

export const restoreCart = (cart) => {
  return dispatch => {
    dispatch({
      type: RESTORE_CART,
      payload: cart
    })
  }
};

export const verifyCoupon = (data) => {
  return async (dispatch) => {
    try {
      let resp = await axios.post('/v1/verify_coupon', data);

      console.log(resp.data)
      dispatch({
        type: VERIFY_COUPON,
        payload: resp.data
      });
    } catch (error) {
      console.log(error.response.data)
      dispatch({
        type: VERIFY_COUPON,
        payload: error.response.data
      });
    }
  }
};

export const clearCoupon = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_COUPON
    })
  }
}