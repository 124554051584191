import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'

const Footer = () => {

  return (
    <Fragment>
      <div className="footer-top">
        <Container className="footer-wrap">
          <Row>
            <Col lg={4} md={12} className="space-pt-20">
              <p className="footer-title">PROJECT ROCK</p>
              <p>
                Established in 2017, Project Rock is
                <span className="block">dedicated to provide all services related</span>
                <span className="block">to rock climbing and grow the sports.</span>
              </p>
              <p className="link">
                <span className="footer-icon"><a href="#"><FaFacebookF /></a></span>
                <span className="footer-icon"><a href="#"><FaTwitter /></a></span>
                <span className="footer-icon"><a href="#"><FaInstagram /></a></span>
              </p>
            </Col>
            <Col lg={4} md={12} className="space-pt-20">
              <p className="link"><a href="#">About Project Rock</a></p>
              <p className="link"><a href="#">Contact Us</a></p>
              <p className="link"><a href="#">FAQs</a></p>
              <p className="link"><a href="#">Privacy Policy</a></p>
              <p className="link"><a href="#">Terms and Conditions</a></p>
            </Col>
            <Col lg={4} md={12} className="space-pt-20">
              <p className="footer-title">CONTACT INFO</p>
              <p>
                Rock Climb Asia Sdn Bhd
                <span className="block">(1318636-W)</span>
                <span className="block">L1.10, IKEA Batu Kawan - Link Building,</span>
                <span className="block">Persiaran Bandar Cassia 8,</span>
                <span className="block">14110 Bandar Cassia, Pulau Pinang.</span>
              </p>
              <p className="contact">
                Email: <a href="mailto:climbers@projectrock.com.my">climbers@projectrock.com.my</a><br/>
                Phone: <a href="tel:045880099">+604 588 0099</a>
              </p>
              {/* <p className="link">
                <a href="https://www.projectrock.com.my/">VIEW DIRECTION</a>
              </p> */}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <Container className="footer-wrap">
          <Row>
            <Col lg={6} md={12} className="space-pt-10">
              <span className="footer-text">Project Rock loves you</span>
            </Col>
            <Col lg={6} md={12} className="copyright space-pt-10">
              <span className="footer-text">Copyright © 2023 Sensoft Technologies</span>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default Footer;