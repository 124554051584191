import axios from 'axios';

export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const SEARCH_MEMBER_ERROR = "SEARCH_MEMBER_ERROR";
export const UPDATE_RENEWAL_CART = "UPDATE_RENEWAL_CART";
export const UPDATE_RENEWAL_CUSTOMER = "UPDATE_RENEWAL_CUSTOMER";
export const RENEWAL_CHECKOUT_SUCCESS = "RENEWAL_CHECKOUT_SUCCESS";
export const RENEWAL_CHECKOUT_FAILED = "RENEWAL_CHECKOUT_FAILED";
export const RESTORE_RENEWAL_CART = "RESTORE_RENEWAL_CART";
export const VERIFY_RENEWAL_COUPON = "VERIFY_RENEWAL_COUPON";
export const CLEAR_RENEWAL_COUPON = "CLEAR_RENEWAL_COUPON";

export const searchMember = (email) => {
  return async (dispatch) => {
    try {
      let resp = await axios.post('/v1/search_member', { _id: email });

      dispatch({
        type: SEARCH_MEMBER,
        payload: resp.data
      });
    } catch (err) {
      dispatch({
        type: SEARCH_MEMBER_ERROR,
        payload: err.response.data
      });
    }
  };
};

export const updateRenewalCart = (data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_RENEWAL_CART,
      payload: data
    });
  };
};

export const renewalCheckout = (customer) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_RENEWAL_CUSTOMER,
      payload: customer
    });

    const cart = getState().memberData.cart;

    window.sessionStorage.setItem('cart', JSON.stringify(cart));

    axios.post('/v1/renew_membership', cart)
      .then((resp) => {
        dispatch({
          type: RENEWAL_CHECKOUT_SUCCESS,
          payload: resp.data
        });
      })
      .catch((error) => {
        dispatch({
          type: RENEWAL_CHECKOUT_FAILED,
          payload: {
            error: 'Renewal membership failed!',
            id: new Date().valueOf() // use for state to refresh only
          }
        });
      });
  };
};

export const restoreRenewalCart = (cart) => {
  return dispatch => {
    dispatch({
      type: RESTORE_RENEWAL_CART,
      payload: cart
    });
  };
};

export const verifyCoupon = (data) => {
  return async (dispatch) => {
    try {
      let resp = await axios.post('/v1/verify_coupon', data);

      dispatch({
        type: VERIFY_RENEWAL_COUPON,
        payload: resp.data
      });
    } catch (error) {
      dispatch({
        type: VERIFY_RENEWAL_COUPON,
        payload: error.response.data
      });
    }
  };
};

export const clearCoupon = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_RENEWAL_COUPON
    });
  };
};