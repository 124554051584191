import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, FormControl, Modal, Button, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTicket, updateExchangeCart, exchangeCheckout } from '../redux/actions/exchangeActions';
import { Controller, useForm } from "react-hook-form";
import Stepper from 'bs-stepper';
import DatePicker from 'react-datepicker';
import { IoIosTrash, IoMdSearch } from 'react-icons/io'
import { cloneDeep, findIndex, isEmpty, remove } from 'lodash';
import dayjs from 'dayjs';
import PaymentForm from '../components/PaymentForm';
import Ipay88Form from '../components/Ipay88Form';
import LayoutWithSideBar from '../components/LayoutWithSideBar';
import productData from '../data/product.json';

const ChangeVisitDate = () => {
  const dispatch = useDispatch();
  const exchange = useSelector(state => state.exchangeData);
  const [stepper, setStepper] = useState();
  const [changedTickets, setChangedTickets] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [cartCustomer, setCartCustomer] = useState({});

  console.log(exchange);

  let subTotal = 0;

  const { cart, payment, item, error } = exchange;

  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('order_id');

  const {
    handleSubmit,
    errors,
    control
  } = useForm({
    mode: 'onChange'
  });

  const onSubmit = (data) => {
    if (data)
      dispatch(fetchTicket(data));
  }

  const removeTicket = (id) => {
    const updateTickets = cloneDeep(changedTickets);

    remove(updateTickets, (item) => {
      return item.ticket[0] === id;
    });

    setChangedTickets(updateTickets);
  }

  const handleSubmitTickets = () => {
    if (changedTickets && changedTickets.length > 0) {
      const data = {
        items: [...changedTickets],
        num_items: changedTickets.length,
        subtotal: subTotal,
        retry_path: {
          link: "change_date"
        }
      }
      dispatch(updateExchangeCart(data));
      stepper.next();
    }
  }

  const getCustomer = (customer) => {
    setCustomer(customer)
  }

  useEffect(() => {
    setStepper(new Stepper(document.querySelector('.bs-stepper'), { animation: true }));
  }, []);

  useEffect(() => {
    console.log(item);
    if (item) {
      const index = findIndex(changedTickets, ['ticket[0]', item.ticket[0]]);
      console.log(index);
      if (index >= 0) {
        let updateTickets = cloneDeep(changedTickets);
        updateTickets.splice(index, 1, item);
        setChangedTickets(updateTickets);
      } else {
        setChangedTickets([
          ...changedTickets,
          item
        ]);

        if (item.order.customer) setCartCustomer(item.order.customer);
      }
    }
  }, [item, setChangedTickets, setCartCustomer]);

  useEffect(() => {
    if (!isEmpty(customer))
      dispatch(exchangeCheckout(customer));
  }, [customer, dispatch])

  useEffect(() => {
    if (orderId) {
      if (cart.items && cart.items.length > 0) {
        setChangedTickets([...cart.items]);
      }
      setCartCustomer(cart.customer);
    }
  }, [orderId]);

  return (
    <LayoutWithSideBar>
      <div id="stepperForm" className="bs-stepper" style={showLoading ? { display: "none" } : {}}>
        <div className="bs-stepper-header" role="tablist">
          <div className="step" data-target="#change-date-form-1">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger1" aria-controls="change-date-form-1" disabled>
              <span className="bs-stepper-circle">1</span>
              <span className="bs-stepper-label">Change Ticket(s)</span>
            </Button>
          </div>
          <div className="bs-stepper-line"></div>
          <div className="step" data-target="#change-date-form-2">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger2" aria-controls="change-date-form-2" disabled>
              <span className="bs-stepper-circle">2</span>
              <span className="bs-stepper-label">Order Summary</span>
            </Button>
          </div>
          <div className="bs-stepper-line"></div>
          <div className="step" data-target="#change-date-form-3">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger3" aria-controls="change-date-form-3" disabled>
              <span className="bs-stepper-circle">3</span>
              <span className="bs-stepper-label">Payment</span>
            </Button>
          </div>
        </div>

        <div className="bs-stepper-content">
          <div id="change-date-form-1" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger1">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="change-date-header">
                Ticket Information
              </div>
              <div className="space-mb-15 grey-panel">
                <Row className="change-date-panel">
                  <Col xl={2} className="space-pt-10 text-left">
                    <span className="form-label">Ticket Number:</span>
                  </Col>
                  <Col xl={4} className="text-left">
                    <Controller
                      name="ticket_id"
                      control={control}
                      defaultValue={""}
                      render={({ onChange, value }) => (
                        <FormControl
                          type="text"
                          defaultValue={value}
                          placeholder="Ticket number"
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errors && errors.ticket_id ? errors.ticket_id.message : ""}</span>}
                  </Col>
                  <Col xl={2} className="space-pt-10 text-left">
                    <span className="form-label">Email Address:</span>
                  </Col>
                  <Col xl={4} className="text-left">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={""}
                      render={({ onChange, value }) => (
                        <FormControl
                          type="email"
                          defaultValue={value}
                          placeholder="example@email.com"
                          disabled={changedTickets && changedTickets.length > 0}
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errors && errors.email ? errors.email.message : ""}</span>}
                  </Col>
                </Row>
                <Row className="change-date-panel">
                  <Col xl={2} className="space-pt-10 text-left">
                    <span className="form-label">New Visit Date:</span>
                  </Col>
                  <Col xl={4} className="text-left visit-date-box">
                    <Controller
                      name="new_date"
                      control={control}
                      render={({ onChange, value }) => (
                        <DatePicker
                          className="form-control visit-date"
                          dateFormat="dd MMMM yyyy"
                          selected={value}
                          onChange={(value) => {
                            onChange(value);
                          }}
                          minDate={dayjs().startOf('day').toDate()}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errors && errors.new_date ? errors.new_date.message : ""}</span>}
                  </Col>
                  <Col xl={6} className="change-date-search">
                    <Button variant="dark" type="submit"> <IoMdSearch /> </Button>
                  </Col>
                </Row>
                {error &&
                  <Row className="justify-content-center change-date-status">
                    {error}
                  </Row>
                }
              </div>
            </form>
            {changedTickets &&
              changedTickets.length > 0 &&
              <Row className="form-body">
                <Table>
                  <thead>
                    <tr>
                      <th className="align-middle">Ticket No</th>
                      <th className="mobile-hide align-middle">Types of Passes</th>
                      <th className="align-middle">New Date</th>
                      <th className="align-middle">Topup (RM)</th>
                      <th className="align-middle">Admin Fee (RM)</th>
                      <th className="align-middle">Total (RM)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {changedTickets.map((item, i) => {
                      subTotal += item.amount;

                      return (
                        <tr key={i}>
                          <td className="align-middle">{item.ticket[0]}</td>
                          <td className="mobile-hide align-middle">{item.pass.desc}</td>
                          <td className="align-middle">{dayjs(item.new_date).startOf('day').format('DD MMMM YYYY')}</td>
                          <td className="align-middle">{item.top_up.toFixed(2)}</td>
                          <td className="align-middle">{item.fee.unit_price.value.toFixed(2)}</td>
                          <td className="align-middle">{item.amount.toFixed(2)}</td>
                          <td className="align-middle"><IoIosTrash onClick={() => removeTicket(item.ticket[0])} /></td>
                        </tr>
                      )
                    })}
                    <tr className="grey-panel">
                      <td className="mobile-hide"></td>
                      <td colSpan='4' className="text-right">Subtotal (RM)</td>
                      <td>{subTotal.toFixed(2)}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            }
            <Row>
              <Col className="form-body">
                {productData.changeDate.length > 0 &&
                  productData.changeDate.map((note, i) => {
                    return (
                      <span key={i} className="text-left block">{note}</span>
                    )
                  })}
              </Col>
            </Row>
            <Row className="row-space-20">
              <Col className="space-pd-0 text-left">
                <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
              </Col>
              <Col className="space-pd-0 text-right">
                <Button className="btn btn-primary mobile-size" onClick={() => handleSubmitTickets()}>Next</Button>
              </Col>
            </Row>
          </div>
          <div id="change-date-form-2" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger2">
            {cart &&
              cart.items &&
              cart.items.length > 0 ? (
              <Fragment>
                <h4 className="row-space-20">Summary of your order items</h4>
                <Row>
                  <Table>
                    <thead>
                      <tr>
                        <th>Ticket No</th>
                        <th className="mobile-hide">Item(s)</th>
                        <th>New Visit Date</th>
                        <th>Topup (RM)</th>
                        <th>Admin Fee (RM)</th>
                        <th>Subtotal (RM)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.items.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item.ticket[0]}</td>
                            <td className="mobile-hide">{item.pass.desc}</td>
                            <td>{dayjs(item.new_date).startOf('day').format('DD MMMM YYYY')}</td>
                            <td>{item.top_up.toFixed(2)}</td>
                            <td>{item.fee.unit_price.value.toFixed(2)}</td>
                            <td>{(item.amount).toFixed(2)}</td>
                          </tr>
                        )
                      })}
                      <tr className="text-bold">
                        <td className="mobile-hide"></td>
                        <td colSpan='4' className="text-right">TOTAL (RM)</td>
                        <td>{cart.subtotal.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row className="row-space-20">
                  <Col className="space-pd-0 text-left">
                    <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
                  </Col>
                  <Col className="space-pd-0 text-right">
                    <Button className="btn btn-primary mobile-size" onClick={() => stepper.previous()}>Back</Button>
                    <span>{' '}</span>
                    <Button className="btn btn-primary mobile-size" onClick={() => stepper.next()}>Buy Now</Button>
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <h3>No order found</h3>
            )
            }
          </div>
          <div id="change-date-form-3" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger3">
            <PaymentForm
              stepper={stepper}
              customer={cartCustomer}
              disableEmail={true}
              getCustomer={getCustomer}
              setShowCancelModal={setShowCancelModal}
            />
          </div>
        </div>
      </div>
      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Change Date?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Changes you made may not be saved.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => window.location.href = "/change_date"}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      { payment && !payment.error &&
        <Ipay88Form
          payment={payment}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
        />
      }
    </LayoutWithSideBar>
  )
}

export default ChangeVisitDate;