import axios from 'axios';

export const GET_GYM_EXCLUSION_DATE = "GET_GYM_EXCLUSION_DATE";
export const GET_GYM_EXCLUSION_DATE_ERROR = "GET_GYM_EXCLUSION_DATE_ERROR";

export const getGymExclusionDate = (data) => {
  return async (dispatch) => {
    try {
      let resp = await axios.post('/v1/get_gym_exclusion_date', { gym: data });
 
      dispatch({
        type: GET_GYM_EXCLUSION_DATE,
        payload: resp.data
      });
    } catch (error) {
      dispatch({
        type: GET_GYM_EXCLUSION_DATE_ERROR,
        payload: error.response.data
      });
    }
  };
};
