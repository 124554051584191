import { every, find, filter, groupBy, map, orderBy, uniq, uniqBy, forEach } from 'lodash';

export const getGroupsByAge = (groups, age) => {
  return filter(groups, (group) =>
    every(group.criteria, (criteria) => {
      let result = false;

      switch (criteria.condition) {
        case '>':
          result = age > criteria.age;
          break;

        case '>=':
          result = age >= criteria.age;
          break;

        case '<':
          result = age < criteria.age;
          break;

        case '<=':
          result = age <= criteria.age;
          break;

        case '==':
          result = age == criteria.age;
          break;
      }

      return result;
    })
  );
};

export const getGroupsByGender = (groups, gender) => {
  // if no male or female, then search for other.
  const result = filter(groups, (group) => group.gender.toLowerCase() === gender.toLowerCase());
  if (result.length > 0)
    return result;
  return filter(groups, (group) => group.gender.toLowerCase() === 'other');
};

export const getGroupsByCategory = (groups, category) => {
  // if no open or closed, then search for other.
  const result = filter(groups, (group) => group.category.toLowerCase() === category.toLowerCase());
  if (result.length > 0)
    return result;
  return filter(groups, (group) => group.category.toLowerCase() === 'other');
};

export const getAgeGrpDesc = (ageGroup) => {
  let desc = '';

  if (ageGroup) {
    let criteriaArr = [];
    forEach(ageGroup.criteria, (criteria) => {
      let criteriaDesc = '';
      switch (criteria.condition) {
        case '>':
          criteriaDesc = `above ${criteria.age}`;
          break;

        case '>=':
          criteriaDesc = `${criteria.age} and above`;
          break;

        case '<':
          criteriaDesc = `below ${criteria.age}`;
          break;

        case '<=':
          criteriaDesc = `${criteria.age} and below`;
          break;

        case '==':
          criteriaDesc = `equal ${criteria.age}`;
          break;
      }
      if (criteriaDesc) criteriaArr.push(criteriaDesc);
    });

    desc = ageGroup.group + `${criteriaArr && criteriaArr.length ? ` (${criteriaArr.join(', ')})` : ''}`;
  }
  return desc;
};

export const getGroupsByVariants = (variants, groups) => {
  let variantGroups = [];

  variants &&
    variants.length > 0 &&
    variants.forEach((variant) => {
      const group = find(groups, (group) => group._id === variant.group);

      if (group) {
        // Note: Commented out to below checking to make sure all variants are added or else we were unable to filter the competition correctly.
        // const exist = find(variantGroups, (vg) => vg.group === variant.group); // avoid duplicate group

        // if (!exist)
          variantGroups.push({
            criteria: group.criteria,
            group: variant.group,
            sku: variant.sku,
            gender: variant.gender ? variant.gender : null,
            category: variant.category ? variant.category : null
          });
      }
    });

  return variantGroups;
};

export const getPassByCategory = (passes, category) => {
  const filteredPass = filter(passes, (ps) => ps.category && ps.category.toLowerCase() === category.toLowerCase());

  return filteredPass;
};

export const getPassTypes = (passes, passCategory) => {
  const filteredPass = getPassByCategory(passes, passCategory);
  const passTypes = map(filteredPass, (ps) => ps._id.toLowerCase().split(' ').join('-'));

  return uniq(passTypes);
};

export const getPassByGroup = (passes, passCategory, gym) => {
  const filteredPass = getPassByCategory(passes, passCategory);

  const filteredGymPass = filter(filteredPass, (ps) => ps.gym && ps.gym.length && ps.gym.findIndex((g) => g.toLowerCase().includes(gym)) !== -1);

  const groupByParentSku = groupBy(filteredGymPass, (ps) => ps.parent_sku);

  return groupByParentSku;
};

export const getPassVariants = (passes, passCategory, passType, gym) => {
  let passVariants = [];

  const filteredPasses = getPassByGroup(passes, passCategory, gym);
  const filteredPassByType = filteredPasses && filteredPasses[passType] ? [...filteredPasses[passType]] : [];

  filteredPassByType &&
    filteredPassByType.forEach((pass) => {
      if (pass && pass.variants && pass.variants.length) {
        passVariants.push(...pass.variants);
      }
    });

  return orderBy(passVariants, ['group'], ['desc']);
};

export const isWeekdaysOnly = (passVariants) => {
  let weekdaysOnly = false;

  if (passVariants && passVariants.length) {
    const blackOutPass = find(passVariants, (ps) => ps.allowable_day && ps.allowable_day.length === 1 && ps.allowable_day[0] === 'WEEKDAYS');
    if (blackOutPass)
      weekdaysOnly = true;
  }

  return weekdaysOnly;
}

export const getCompetition = (workshops, type, id) => {
  // Filter by type: Competition or Workshop
  const filteredCompetitionByType = filter(workshops, (o) => o.type && o.type.toLowerCase() === type.toLowerCase());

  // Filter by id
  const filteredCompetitionById = filter(filteredCompetitionByType, (o) => o._id.replace(/\s+/g, '-').toLowerCase() === id.toLowerCase());

  return filteredCompetitionById;
};

export const getCompetitionVariants = (competition) => {
  let competitionVariants = [];

  competition &&
    competition.forEach((c) => {
      if (c && c.variants && c.variants.length) {
        competitionVariants.push(...c.variants);
      }
    });

  return orderBy(competitionVariants, ['group'], ['gender'], ['desc']);
};

export const getWorkshop = (workshops, type, cls, gym) => {
  // Filter by type: Competition or Workshop
  const filteredWorkshopByType = filter(workshops, (o) => o.type && o.type.toLowerCase() === type.toLowerCase());

  // Filter by class
  const filteredWorkshopByCls = filter(filteredWorkshopByType, (o) => o.classes && o.classes.replace(/\s+/g, '-').toLowerCase() === cls.toLowerCase())

  // Filter by gym
  const filteredWorkshopByGym = filter(filteredWorkshopByCls, (o) => o.gym && o.gym.length && o.gym.findIndex((g) => g.toLowerCase().includes(gym)) !== -1);

  return filteredWorkshopByGym;
};

export const getWorkshopVariants = (workshop) => {
  let workshopVariants = [];

  workshop &&
    workshop.forEach((ws) => {
      if (ws && ws.variants && ws.variants.length) {
        workshopVariants.push(...ws.variants);
      }
    });

  return orderBy(workshopVariants, ['group'], ['desc']);
};

export const getWorkshopClasses = (workshops, type, gym) => {
  // Filter by workshop type
  const filteredWorkshopByType = filter(workshops, (o) => o.type && o.type.toLowerCase() === type.toLowerCase());

  // Filter by gym
  const filteredWorkshopByGym = filter(filteredWorkshopByType, (o) => o.gym && o.gym.length && o.gym.findIndex((g) => g.toLowerCase().includes(gym)) !== -1);

  // Filter by unique classes
  const uniqueClassWorkshop = uniqBy(filteredWorkshopByGym, 'classes');

  // return unique classes
  return map(uniqueClassWorkshop, 'classes');
};

export const getVariantByGym = (variants, gym) => {
  const filteredVariant = filter(variants, (v) => gym === 'Multigyms' ? v.gym && v.gym.length > 1 : v.gym && v.gym.length === 1 && v.gym[0] === gym);

  return filteredVariant;
};

export const getGroupIdByAge = (groups, age) => {
  const filteredGroup = getGroupsByAge(groups, age);
  return map(filteredGroup, '_id');
};

export const getPassVariantsByGroup = (passes, groups, age) => {
  let passVariants = [];

  const ageGroup = getGroupIdByAge(groups, age);

  passes &&
    passes.forEach((pass) => {
      pass &&
        pass.variants &&
        pass.variants.length &&
        pass.variants.forEach((variant) => {
          if (ageGroup.includes(variant.group))
            passVariants.push(variant);
        });
    });

  return orderBy(passVariants, ['desc']);
};

export const getMinDays = (variants) => {
  let daysAhead = 999;

  variants &&
    variants.length > 0 &&
    variants.forEach((variant) => {
      variant &&
        variant.price &&
        variant.price.length > 0 &&
        variant.price.forEach((price) => {
          if (price.days_ahead < daysAhead) daysAhead = price.days_ahead;
        });
    });
  return daysAhead;
}

export const getGroupDesc = (groups, groupVariant) => {
  let groupDesc = "";

  if (groups && groups.length > 0) {
    let group = find(groups, ['_id', groupVariant]);

    groupDesc = group._id + " (" + group.desc + ")";
  }

  return groupDesc;
};

export const getTicketPrice = (prices, daysAhead) => {
  if (prices && prices.length > 0) {
    let discountPrices = orderBy(filter(prices, (price) => { return price.days_ahead <= (daysAhead >= 0 ? daysAhead : 0) }), ['days_ahead'], ['desc']);

    if (discountPrices && discountPrices.length > 0)
      return discountPrices[0];
  }

  return { value: 0 };
};

export const getGyms = (passes) => {
  let gyms = [];

  if (passes) {
    const result = [].concat.apply([], map(passes, 'gym'));
    gyms = uniq(result);
    if (gyms && gyms.length > 1)
      gyms.splice(0, 0, "Multigyms");
  }

  return gyms;
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};