import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchPasses } from '../redux/actions/productActions';
import { getPassByGroup } from '../lib/product';
import LayoutWithSideBar from '../components/LayoutWithSideBar';
import productData from "../data/product.json";

const PassTypes = ({ passes, fetchPasses }) => {

  useEffect(() => {
    fetchPasses();
  }, [fetchPasses])

  let { gym, pass } = useParams();

  const filteredPass = getPassByGroup(passes, pass.replace(/[-]+/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, (c) => c.toUpperCase()), gym.replace(/-/g, " ").toLowerCase());
  const passType = Object.keys(filteredPass).sort();

  console.log(passType);

  return (
    <LayoutWithSideBar>
      {passType && passType.map((type, i) => {
        const style = {
          backgroundImage: 'url(\'/assets/images/passes/' + gym + '-' + type.toLowerCase() + '.png\')'
        };
        return (
          <Row key={i} className="space-pb-30">
            <Col md={5} sm={12}>
              <Link to={`/online_ticket/${gym}/${pass}/${type}`}>
                <div className='pass-types' style={style} />
              </Link>
            </Col>
            <Col md={7} sm={12} className="space-mt-10">
              <Col xl={9} lg={10} md={12}>
                <p className="pass-description">
                  {productData.passTypes[gym][pass][type]}
                </p>
              </Col>
            </Col>
          </Row>
        )
      })}
    </LayoutWithSideBar>
  )
}

const mapStateToProps = (state) => {
  return {
    passes: state.productData.passes
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPasses: () => {
      dispatch(fetchPasses());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PassTypes);