import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col className="page-wrap-4">
          <span className="d-block header-4">404</span>
          <div className="text-4">The page you are looking for was not found.</div>
          <a href="https://www.projectrock.com.my/" className="link-4">Back to Home</a>
          <a href="/">Back to Buy Ticket</a>
        </Col>
      </Row>
    </Container>
  )
}

export default NotFound;