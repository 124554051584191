import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Row, Col, FormControl, FormLabel, InputGroup, Overlay, Tooltip, Modal, Button, Table } from 'react-bootstrap';
import { ArrowReturnLeft, Trash, Eraser } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { searchMember, updateRenewalCart, renewalCheckout, verifyCoupon, clearCoupon } from '../redux/actions/memberActions';
import { fetchGroups, fetchPasses } from '../redux/actions/productActions';
import { Controller, useForm } from "react-hook-form";
import Stepper from 'bs-stepper';
import DatePicker from 'react-datepicker';
import { IoMdSearch } from 'react-icons/io'
import { find, isEmpty, range, size } from 'lodash';
import dayjs from 'dayjs';
import { getGyms, getPassByCategory, getPassVariantsByGroup, getTicketPrice, getVariantByGym } from '../lib/product';
import PaymentForm from '../components/PaymentForm';
import Ipay88Form from '../components/Ipay88Form';
import LayoutWithSideBar from '../components/LayoutWithSideBar';
import productData from '../data/product.json';

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

const RenewMember = () => {
  const dispatch = useDispatch();
  const tooltipRef = useRef(null);
  const memberData = useSelector(state => state.memberData);
  const passProductData = useSelector(state => state.productData);
  const [stepper, setStepper] = useState();
  const [daysAhead, setDaysAhead] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState({});
  const [membershipPasses, setMembershipPasses] = useState(null);
  const [gyms, setGyms] = useState([]);
  const [selectedGym, setSelectedGym] = useState('All');
  const [age, setAge] = useState(0);
  const [passVariants, setPassVariants] = useState([]);
  const [passVariantsByGroup, setPassVariantsByGroup] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [cartCustomer, setCartCustomer] = useState({});
  const [couponCode, setCouponCode] = useState('');
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponError, setCouponError] = useState('');

  let ticketCount = 0;
  let subTotal = 0;
  let ticketPrices = [];
  let normalPrices = [];

  const { member, cart, payment, error } = memberData;
  const { passes, groups } = passProductData;

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchPasses());
  }, []);

  useEffect(() => {
    setMembershipPasses(getPassByCategory(passes, 'Membership'));
  }, [passes]);

  useEffect(() => {
    setGyms(getGyms(membershipPasses));
  }, [membershipPasses]);

  useEffect(() => {
    setPassVariants(
      getPassVariantsByGroup(membershipPasses, groups, age)
    );
  }, [membershipPasses, age, groups]);

  useEffect(() => {
    let filteredVariants = passVariants;
    if (selectedGym !== 'All') {
      filteredVariants = getVariantByGym(filteredVariants, selectedGym);
    }
    setPassVariantsByGroup(filteredVariants);
  }, [passVariants, selectedGym]);

  useEffect(() => {
    if (member) {
      setAge(dayjs().startOf('day').diff(dayjs(member.dob).startOf('day'), 'year'));
      if (isEmpty(selectedTickets) && member.ticket && member.ticket.pass) {
        setSelectedTickets({
          [member.ticket.pass.sku]: "1"
        });
      }
    }
  }, [member]);

  useEffect(() => {
    if (!couponCode || couponCode.length === 0) {
      dispatch(clearCoupon());
    }
  }, [couponCode]);

  useEffect(() => {
    const { coupon } = cart;

    if (coupon) {
      let dataType = typeof coupon;
      if (dataType === 'object') {
        if (coupon.total_discount > 0) {
          setCouponError('');
          setCouponDiscount(cart.coupon.total_discount);
        } else {
          setCouponError('The code is not applicable to this order');
          setCouponDiscount(0);
        }
      } else if (dataType === 'string') {
        setCouponError(cart.coupon);
        setCouponDiscount(0);
      }
    } else {
      setCouponCode('');
      setCouponError('');
      setCouponDiscount(0);
    }
  }, [cart.coupon]);


  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('order_id');

  const {
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    trigger,
    errors,
    control
  } = useForm({
    mode: 'onChange'
  });

  const onSubmit = (data) => {
    if (data && data.email)
      dispatch(searchMember(data.email))
  }

  const handleSubmitRenew = () => {
    if (!isEmpty(selectedTickets)) {

      // check expiry
      const visitDate = member.expired && dayjs().isSameOrBefore(dayjs(member.expired), 'day')
        ? dayjs(member.expired).add(1, 'day') : dayjs();

      let items = [];
      let itemsNum = 0;
      for (let key in selectedTickets) {
        if (parseInt(selectedTickets[key])) {
          itemsNum += parseInt(selectedTickets[key]);
          let variant = find(passVariantsByGroup, ['sku', key]);
          let ticketPrice = find(ticketPrices, (o) => { return o[key] });
          let normalPrice = find(normalPrices, (o) => { return o[key] });

          if (variant) {
            const parentPass = find(membershipPasses, (pass) => {
              return pass.variants.includes(variant)
            });

            items.push({
              ...variant,
              quantity: parseInt(selectedTickets[key]),
              visit_date: dayjs(visitDate).startOf('day').toDate(),
              type: "RENEWAL",
              name: parentPass?._id + " " + variant.group,
              price: isEmpty(ticketPrice) ? null : ticketPrice[key],
              normalPrice: isEmpty(normalPrice) ? null : normalPrice[key],
              member: member
            });
          }
        }
      }

      const data = {
        items: [...items],
        num_items: itemsNum,
        subtotal: subTotal,
        retry_path: {
          link: "renew_member"
        }
      };

      dispatch(updateRenewalCart(data));
      stepper.next();
    } else {
      trigger();
    }
  }

  const handleChangeTickets = (e) => {
    passVariantsByGroup &&
      passVariantsByGroup.forEach((variant) => {
        if (variant.sku !== e.target.name)
          setValue(variant.sku, '');
      });

    setSelectedTickets({
      [e.target.name]: e.target.value
    });
  }

  const handleCouponCode = () => {
    if (couponCode.length === 0) return;

    if (couponError.length > 0) {
      setCouponCode('');
      return;
    }

    if (couponDiscount > 0) {
      dispatch(clearCoupon());
    } else
      dispatch(verifyCoupon({ code: couponCode, cart: cart }));
  }

  const handleBack = () => {
    dispatch(clearCoupon());
    stepper.previous();
  }

  const validateTicketCount = () => {
    const data = getValues();
    ticketCount = 0
    passVariantsByGroup &&
      passVariantsByGroup.forEach((variant) => {
        clearErrors([variant.sku]);
        if (data[variant.sku]) {
          ticketCount += parseInt(data[variant.sku]);
        }
      });

    if (ticketCount) return true;
    else return false;
  }
  const getCustomer = (customer) => {
    setCustomer(customer)
  }

  useEffect(() => {
    setStepper(new Stepper(document.querySelector('.bs-stepper'), { animation: true }));
  }, []);

  useEffect(() => {
    if (member && !isEmpty(member)) {
      setCartCustomer({
        name: member.name ? member.name : '',
        phone: member.phone && !isEmpty(member.phone) ? member.phone : null,
        email: member._id ? member._id : ''
      });
    }
  }, [member, setCartCustomer]);

  useEffect(() => {
    if (!isEmpty(customer))
      dispatch(renewalCheckout(customer));
  }, [customer, dispatch])

  useEffect(() => {
    if (orderId) {
      if (member && !isEmpty(member))
        setValue('email', member._id)
      if (cart.items && cart.items.length > 0) {
        let selectTickets = {};
        cart.items.forEach((item) => {
          selectTickets[item.sku] = selectTickets[item.sku]
            ? (parseInt(selectTickets[item.sku]) + item.quantity).toString()
            : item.quantity.toString();
        });

        setSelectedTickets(selectTickets);
      }
      setCartCustomer(cart.customer);
    }
  }, [orderId]);

  return (
    <LayoutWithSideBar>
      <div id="stepperForm" className="bs-stepper" style={showLoading ? { display: "none" } : {}}>
        <div className="bs-stepper-header" role="tablist">
          <div className="step" data-target="#renew-member-form-1">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger1" aria-controls="renew-member-form-1" disabled>
              <span className="bs-stepper-circle">1</span>
              <span className="bs-stepper-label">Renew Membership</span>
            </Button>
          </div>
          <div className="bs-stepper-line"></div>
          <div className="step" data-target="#renew-member-form-2">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger2" aria-controls="renew-member-form-2" disabled>
              <span className="bs-stepper-circle">2</span>
              <span className="bs-stepper-label">Order Summary</span>
            </Button>
          </div>
          <div className="bs-stepper-line"></div>
          <div className="step" data-target="#renew-member-form-3">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger3" aria-controls="renew-member-form-3" disabled>
              <span className="bs-stepper-circle">3</span>
              <span className="bs-stepper-label">Payment</span>
            </Button>
          </div>
        </div>

        <div className="bs-stepper-content">
          <div id="renew-member-form-1" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger1">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="renew-member-header">
                Member Information
              </div>
              <div className="space-mb-15 grey-panel">
                <Row className="renew-member-panel">
                  <Col xl={2} className="space-pt-10 text-left">
                    <span className="form-label">Email Address:</span>
                  </Col>
                  <Col xl={4} className="text-left">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={""}
                      render={({ onChange, value }) => (
                        <FormControl
                          type="email"
                          defaultValue={value}
                          placeholder="example@email.com"
                          disabled={member && !isEmpty(member)}
                          onChange={(e) => onChange(e.target.value)}
                        />
                      )}
                      rules={{ required: "This field is required" }}
                    />
                    {<span className="error-text mobile-size">{errors && errors.email ? errors.email.message : ""}</span>}
                  </Col>
                  <Col xl={6} className="renew-member-search">
                    <Button variant="dark" type="submit"> <IoMdSearch /> </Button>
                  </Col>
                </Row>
                {member &&
                  <Row className="renew-member-panel">
                    <Col xl={2} className="space-pt-10 text-left">
                      <span className="form-label">Name:</span>
                    </Col>
                    <Col xl={4} className="text-left">
                      <Controller
                        name="name"
                        control={control}
                        defaultValue={member.name ? member.name : ''}
                        render={({ onChange, value }) => (
                          <FormControl
                            type="text"
                            defaultValue={value}
                            placeholder="Full name as per IC / Passport"
                            onChange={(e) => onChange(e.target.value)}
                            disabled={member.name ? true : false}
                          />
                        )}
                        rules={{ required: "This field is required" }}
                      />
                      {<span className="error-text mobile-size">{errors && errors.name ? errors.name.message : ""}</span>}
                    </Col>
                    <Col xl={2} className="space-pt-10 text-left">
                      <span className="form-label">Expiry Date:</span>
                    </Col>
                    <Col xl={4} className="text-left renew-date-box">
                      <Controller
                        name="expired"
                        control={control}
                        defaultValue={member.expired ? dayjs(member.expired).endOf('day').toDate() : ''}
                        render={({ onChange, value }) => (
                          <DatePicker
                            className="form-control renew-date"
                            dateFormat="dd MMMM yyyy"
                            selected={value}
                            onChange={(value) => {
                              onChange(value);
                            }}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            disabled
                          />
                        )}
                      />
                      {<span className="error-text mobile-size">{errors && errors.expired ? errors.expired.message : ""}</span>}
                    </Col>
                  </Row>
                }
                {error &&
                  <Row className="justify-content-center renew-member-status">
                    {error}
                  </Row>
                }
              </div>
              {member &&
                <Fragment>
                  <Row className="form-body">
                    <Col xl={{ span: 4, offset: 8 }} className="text-left space-pt-20">
                      <div className="form-group floating">
                        <Controller
                          name="gym"
                          control={control}
                          defaultValue={'All'}
                          render={({ onChange, value }) => (
                            <FormControl
                              className="floating"
                              as="select"
                              defaultValue={value}
                              placeholder="Select gym"
                              onChange={(e) => {
                                onChange(e.target.value);
                                setSelectedGym(e.target.value);
                              }}
                            >
                              <option value='All'>All</option>
                              {gyms && gyms.length > 0 && gyms.map((gym) => {
                                return (
                                  <option value={gym} key={gym}>{gym}</option>
                                )
                              })}
                            </FormControl>
                          )}
                        />
                        <FormLabel>Select GYM</FormLabel>
                      </div>
                    </Col>
                  </Row>

                  {passVariantsByGroup && passVariantsByGroup.length > 1 ? (
                    <Fragment>
                      <div className="d-flex justify-content-center grey-panel">
                        <Row className="d-block grey-panel-row">
                          <span>Membership</span>
                        </Row>
                      </div>
                      <Row className="form-body">
                        <Table>
                          <thead>
                            <tr>
                              <th className="align-middle">Types of Memberships</th>
                              <th className="align-middle">Duration</th>
                              <th className="align-middle">Online Price (RM)</th>
                              <th className="align-middle">Quantity</th>
                              <th className="align-middle">Total (RM)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {passVariantsByGroup.map((variant) => {
                              const oriPrice = getTicketPrice(variant.price, 0);
                              const ticketPrice = getTicketPrice(variant.price, daysAhead);
                              const totalPrice = selectedTickets[variant.sku] ? selectedTickets[variant.sku] * ticketPrice.value : 0;

                              let opObj = {};
                              opObj[variant.sku] = oriPrice;
                              normalPrices.push(opObj);

                              let tpObj = {};
                              tpObj[variant.sku] = ticketPrice;
                              ticketPrices.push(tpObj);

                              subTotal += totalPrice;
                              return (
                                <tr key={variant.sku}>
                                  <td className="align-middle">
                                    {variant.desc}
                                  </td>
                                  <td className="align-middle">
                                    {variant.duration.value} {variant.duration.unit}
                                  </td>
                                  <td className="align-middle">
                                    {oriPrice.value > ticketPrice.value &&
                                      <Fragment>
                                        <span className="price-discount">{oriPrice.value.toFixed(2)}</span>{' '}
                                        <br className="mobile-only"></br>
                                      </Fragment>
                                    }
                                    {ticketPrice.value.toFixed(2)}
                                  </td>
                                  <td className="align-middle">
                                    <Controller
                                      name={variant.sku}
                                      control={control}
                                      defaultValue={selectedTickets[variant.sku] ? selectedTickets[variant.sku].toString() : ""}
                                      render={({ name, onChange, value }) => (
                                        <FormControl
                                          className={errors &&
                                            ((errors.email && size(errors) > 1) ||
                                              (!errors.email && size(errors) > 0)) ? "validation quantity" : "quantity"}
                                          name={name}
                                          as="select"
                                          value={value}
                                          onChange={(e) => {
                                            onChange(e.target.value);
                                            handleChangeTickets(e);
                                          }}
                                        >
                                          <option value=''></option>
                                          {range(variant.min_qty, variant.min_qty * 20).map((i) => {
                                            return (
                                              <option value={i.toString()} key={i}>{i}</option>
                                            )
                                          })}
                                        </FormControl>
                                      )}
                                      rules={{ validate: validateTicketCount }}
                                    />
                                    {
                                      <span className="error-text mobile-size">
                                        <b>{errors &&
                                          ((errors.email && size(errors) > 1) ||
                                            (!errors.email && size(errors) > 0)) ? "Min 1 ticket" : ""}
                                        </b>
                                      </span>
                                    }
                                  </td>
                                  <td className="align-middle">
                                    {totalPrice.toFixed(2)}
                                  </td>
                                </tr>
                              )
                            })}
                            <tr className="grey-panel">
                              <td colSpan='4' className="text-right">Subtotal (RM)</td>
                              <td>{subTotal.toFixed(2)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Row>
                    </Fragment>
                  ) : (
                    <div className="d-flex justify-content-center grey-panel">
                      <Row className="d-block grey-panel-row">
                        <span>No Product Found</span>
                      </Row>
                    </div>
                  )}
                </Fragment>
              }
            </form>
            <Row>
              <Col className="form-body">
                {productData.renewMember.length > 0 &&
                  productData.renewMember.map((note, i) => {
                    return (
                      <span key={i} className="text-left block">{note}</span>
                    )
                  })}
              </Col>
            </Row>
            <Row className="row-space-20">
              <Col className="space-pd-0 text-left">
                <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
              </Col>
              <Col className="space-pd-0 text-right">
                <Button className="btn btn-primary mobile-size" onClick={() => handleSubmitRenew()}>Next</Button>
              </Col>
            </Row>
          </div>
          <div id="renew-member-form-2" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger2">
            {cart &&
              cart.items &&
              cart.items.length > 0 ? (
              <Fragment>
                <h4 className="row-space-20">Summary of your order items</h4>
                <p className="mobile-only" style={{ margin: "0" }}>
                  Start Date: {dayjs(cart.items[0].visit_date).startOf('day').format('DD MMMM YYYY')}
                </p>
                <p className="mobile-only">
                  End Date: {dayjs(cart.items[0].visit_date).startOf('day').add(cart.items[0].duration.value * cart.items[0].quantity, cart.items[0].duration.unit.toLowerCase()).subtract(1, 'day').format('DD MMMM YYYY')}
                </p>
                <Row>
                  <Table>
                    <thead>
                      <tr>
                        <th>Item(s)</th>
                        <th className="mobile-hide">Start Date</th>
                        <th className="mobile-hide">End Date</th>
                        <th>Price (RM)</th>
                        <th>Quantity</th>
                        <th>Subtotal (RM)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.items.map((item, i) => {
                        const oriPrice = getTicketPrice([item.normalPrice], 0);
                        const ticketPrice = getTicketPrice([item.price], daysAhead);
                        const subTotalPrice = item.quantity * ticketPrice.value;
                        return (
                          <tr key={i}>
                            <td>{item.desc}</td>
                            <td className="mobile-hide">{dayjs(item.visit_date).startOf('day').format('DD MMMM YYYY')}</td>
                            <td className="mobile-hide">{dayjs(item.visit_date).startOf('day').add(item.duration.value * item.quantity, item.duration.unit.toLowerCase()).subtract(1, 'day').format('DD MMMM YYYY')}</td>
                            <td>
                              {oriPrice.value > ticketPrice.value &&
                                <Fragment>
                                  <span className="price-discount">{oriPrice.value.toFixed(2)}</span>{' '}
                                </Fragment>
                              }
                              {ticketPrice.value.toFixed(2)}
                            </td>
                            <td>{item.quantity}</td>
                            <td>{subTotalPrice.toFixed(2)}</td>
                          </tr>
                        )
                      })}
                      <tr className="text-bold">
                        <td className="mobile-hide"></td>
                        <td className="mobile-hide"></td>
                        <td colSpan='2' className="text-right">Ticket Amount</td>
                        <td></td>
                        <td>{cart.subtotal?.toFixed(2)}</td>
                      </tr>
                      <tr className="text-bold">
                        <td className="mobile-hide"></td>
                        <td className="mobile-hide"></td>
                        <td colSpan='2' className="text-right" style={{ verticalAlign: 'middle' }}>
                          {(cart.coupon && couponDiscount > 0)
                            ? 'Discount (' + cart.coupon.code + ')'
                            : 'Discount'
                          }
                        </td>
                        <td colSpan='2'>
                          {(cart.coupon && couponDiscount > 0)
                            ? <div style={{ verticalAlign: 'middle' }}>- {couponDiscount.toFixed(2)} <Trash size={18} style={{ float: 'right', cursor: 'pointer' }} onClick={handleCouponCode} /></div>
                            : <InputGroup size="sm">
                              <FormControl
                                ref={tooltipRef}
                                placeholder="Coupon"
                                aria-label="Coupon"
                                aria-describedby="coupon-code"
                                className="mobile-size"
                                htmlSize="10"
                                onChange={(e) => setCouponCode(e.target.value)}
                                value={couponCode}
                                onKeyPress={(key) => {
                                  if (key.code === 'Enter' || key.code === 'NumpadEnter') handleCouponCode();
                                }}
                                disabled={couponDiscount > 0}
                              />
                              <Overlay target={tooltipRef} show={couponError.length > 0} placement='top'>
                                {(props) => (
                                  <Tooltip id="mesg-tooltip" {...props}>
                                    {couponError}
                                  </Tooltip>
                                )}
                              </Overlay>
                              <InputGroup.Append>
                                <Button variant="outline-secondary" className="mobile-size" onClick={handleCouponCode}>{(couponError.length > 0) ? <Eraser /> : <ArrowReturnLeft />}</Button>
                              </InputGroup.Append>
                            </InputGroup>
                          }
                        </td>
                      </tr>
                      <tr className="text-bold">
                        <td className="mobile-hide"></td>
                        <td className="mobile-hide"></td>
                        <td colSpan='2' className="text-right">TOTAL (RM)</td>
                        <td></td>
                        <td>{(cart.subtotal - couponDiscount)?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row className="row-space-20">
                  <Col className="space-pd-0 text-left">
                    <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
                  </Col>
                  <Col className="space-pd-0 text-right">
                    <Button className="btn btn-primary mobile-size" onClick={handleBack}>Back</Button>
                    <span>{' '}</span>
                    <Button className="btn btn-primary mobile-size" onClick={() => stepper.next()}>Buy Now</Button>
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <h3>No order found</h3>
            )
            }
          </div>
          <div id="renew-member-form-3" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger3">
            <PaymentForm
              stepper={stepper}
              customer={cartCustomer}
              disableEmail={true}
              getCustomer={getCustomer}
              setShowCancelModal={setShowCancelModal}
            />
          </div>
        </div>
      </div>
      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Membership Renewal?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Changes you made may not be saved.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => window.location.href = "/renew_member"}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {payment && !payment.error &&
        <Ipay88Form
          payment={payment}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
        />
      }
    </LayoutWithSideBar>
  )
}

export default RenewMember;