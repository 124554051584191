import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import LoadingIndicator from './LoadingIndicator';

const Ipay88Form = ({
  payment,
  showLoading,
  setShowLoading
}) => {

  const [showErrorModal, setShowErrorModal] = useState(false);

  const ipay88Button = useRef(null);

  useEffect(() => {
    if (payment) {
      if (payment.error) {
        setShowErrorModal(true);
      } else {
        window.scrollTo(0, 0);
        setShowLoading(true);
        ipay88Button.current.click();
      }
    }
  }, [payment]);

  return (
    <Fragment>
      {showLoading &&
        <LoadingIndicator message="Contacting payment gateway" />
      }
      <form style={{ display: 'none' }} method="POST" name="ePayment" action="https://ticket.sensoft.cloud:8998/v1/payment_shortcut">
        <input name="MerchantCode" value={payment.MerchantCode} type="hidden"/>
        <input name="PaymentId" value="" type="hidden"/>
        <input name="RefNo" value={payment.RefNo} type="hidden"/>
        <input name="Amount" value={payment.Amount} type="hidden"/>
        <input name="Currency" value={payment.Currency} type="hidden"/>
        <input name="ProdDesc" value={payment.ProdDesc} type="hidden"/>
        <input name="UserName" value={payment.UserName} type="hidden"/>
        <input name="UserEmail" value={payment.UserEmail} type="hidden"/>
        <input name="UserContact" value={payment.UserContact} type="hidden"/>
        <input name="Remark" value={payment.Remark} type="hidden"/>
        <input name="Lang" value={payment.Lang} type="hidden"/>
        <input name="SignatureType" value={payment.SignatureType} type="hidden"/>
        <input name="Signature" value={payment.Signature} type="hidden"/>
        <input name="ResponseURL" value={payment.ResponseURL} type="hidden"/>
        <input name="BackendURL" value={payment.BackendURL} type="hidden"/>
        <input type="submit" value="Proceed with Payment" name="Submit" ref={ipay88Button} />
      </form>
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{payment?.error}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowErrorModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

export default Ipay88Form;