import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from './Sidebar';

const LayoutWithSideBar = (props) => {
  return (
    <Container fluid className="layout">
      <Row className="space-ptb-10">
        <Col lg={3} className="d-none d-lg-block">
          <Sidebar/>
        </Col>
        <Col lg={9} md={12}>
          {props.children}
        </Col>
      </Row>
    </Container>
  )
}

export default LayoutWithSideBar;