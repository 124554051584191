import axios from 'axios';
import { orderBy } from 'lodash';


export const FETCH_PASSES = "FETCH_PASSES";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const FETCH_WORKSHOPS = "FETCH_WORKSHOPS";

// fetch passes
export const fetchPasses = () => {
  return async (dispatch) => {
    try {
      let resp = await axios.get('/v1/passes/ONLINE');

      let data = orderBy(resp.data, ['_id'], ['desc']);

      // if (data && data.length)
      //   data.forEach(d => d.variants.forEach(v => v.gym = d.gym));

      dispatch({
        type: FETCH_PASSES,
        payload: data
      });
    } catch (err) {
      dispatch({
        type: FETCH_PASSES,
        payload: []
      });
    }
  }
};

// fetch groups
export const fetchGroups = () => {
  return async (dispatch) => {
    try {
      let resp = await axios.get('/v1/groups');

      dispatch({
        type: FETCH_GROUPS,
        payload: resp.data
      });
    } catch (err) {
      dispatch({
        type: FETCH_GROUPS,
        payload: []
      });
    }
  }
};

// fetch categories
export const fetchCategories = () => {
  return async (dispatch) => {
    try {
      let resp = await axios.get('/v1/fetch_categories');

      dispatch({
        type: FETCH_CATEGORIES,
        payload: resp.data
      });
    } catch (err) {
      dispatch({
        type: FETCH_CATEGORIES,
        payload: []
      });
    }
  }
};

// fetch workshops
export const fetchWorkshops = () => {
  return async (dispatch) => {
    try {
      let resp = await axios.get('/v1/workshops/ONLINE');

      let data = orderBy(resp.data, ['_id'], ['desc']);

      // if (data && data.length)
      //   data.forEach(d => d.variants.forEach(v => v.gym = d.gym));

      dispatch({
        type: FETCH_WORKSHOPS,
        payload: data
      });
    } catch (err) {
      dispatch({
        type: FETCH_WORKSHOPS,
        payload: []
      });
    }
  }
};
