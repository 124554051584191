import axios from 'axios';

export const CHANGE_DATE = "CHANGE_DATE";
export const CHANGE_DATE_ERROR = "CHANGE_DATE_ERROR";
export const UPDATE_EXCHANGE_CART = "UPDATE_EXCHANGE_CART";
export const UPDATE_EXCHANGE_CUSTOMER = "UPDATE_EXCHANGE_CUSTOMER";
export const EXCHANGE_CHECKOUT_SUCCESS = "EXCHANGE_CHECKOUT_SUCCESS";
export const EXCHANGE_CHECKOUT_FAILED = "EXCHANGE_CHECKOUT_FAILED";
export const RESTORE_EXCHANGE_CART = "RESTORE_EXCHANGE_CART";

export const fetchTicket = (ticket) => {
  return async (dispatch) => {
    try {
      let resp = await axios.post('/v1/change_date', ticket);

      dispatch({
        type: CHANGE_DATE,
        payload: resp.data
      });
    } catch (err) {
      dispatch({
        type: CHANGE_DATE_ERROR,
        payload: err.response.data
      });
    }
  }
};

export const updateExchangeCart = (data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_EXCHANGE_CART,
      payload: data
    });
  }
};

export const exchangeCheckout = (customer) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_EXCHANGE_CUSTOMER,
      payload: customer
    });

    const cart = getState().exchangeData.cart;

    window.sessionStorage.setItem('cart', JSON.stringify(cart));

    axios.post('/v1/commit_date', cart)
    .then((resp) => {
      console.log(resp)
      dispatch({
        type: EXCHANGE_CHECKOUT_SUCCESS,
        payload: resp.data
      });
    })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: EXCHANGE_CHECKOUT_FAILED,
        payload: {
          error: 'Change date failed!',
          id: new Date().valueOf() // use for state to refresh only
        }
      });
    });
  }
};

export const restoreExchangeCart = (cart) => {
  return dispatch => {
    dispatch({
      type: RESTORE_EXCHANGE_CART,
      payload: cart
    })
  }
};