import {
  CHANGE_DATE,
  CHANGE_DATE_ERROR,
  UPDATE_EXCHANGE_CART,
  UPDATE_EXCHANGE_CUSTOMER,
  EXCHANGE_CHECKOUT_SUCCESS,
  EXCHANGE_CHECKOUT_FAILED,
  RESTORE_EXCHANGE_CART
} from '../actions/exchangeActions';

const initState = {
  cart: {
    customer: {},
    items: [],
    num_items: 0,
    subtotal: 0,
    retry_path: null
  },
  payment: null,
  item: null,
  error: ""
}

const exchangeReducer = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_DATE:
      if (action.payload) {
        return {
          ...state,
          item: action.payload,
          error: ""
        };
      }
      break;

    case CHANGE_DATE_ERROR:
      if (action.payload) {
        return {
          ...state,
          item: null,
          error: action.payload
        };
      }
      break;

    case UPDATE_EXCHANGE_CART:
      if (action.payload) {
        return {
          ...state,
          cart: {
            ...state.cart,
            items: [
              ...action.payload.items
            ],
            num_items: action.payload.num_items,
            subtotal: action.payload.subtotal,
            retry_path: action.payload.retry_path
          },
          item: null,
          error: ""
        };
      }
      break;

    case UPDATE_EXCHANGE_CUSTOMER:
      if (action.payload) {
        return {
          ...state,
          cart: {
            ...state.cart,
            customer: action.payload
          }
        };
      }
      break;

    case EXCHANGE_CHECKOUT_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          payment: action.payload
        };
      }
      break;

    case EXCHANGE_CHECKOUT_FAILED:
      if (action.payload) {
        return {
          ...state,
          payment: action.payload
        };
      } else
        return { ...initState };

    case RESTORE_EXCHANGE_CART:
      if (action.payload) {
        return {
          ...initState,
          cart: {
            ...action.payload
          }
        };
      }
      break;

    default:
      return state;
  }
}

export default exchangeReducer;