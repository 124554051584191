import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormControl, Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';

const PaymentForm = ({
  stepper,
  customer,
  disableEmail,
  getCustomer,
  setShowCancelModal
}) => {

  const [phone, setPhone] = useState({});

  const {
    handleSubmit,
    register,
    setValue,
    errors,
    control
  } = useForm({
    mode: 'onChange'
  });

  const onSubmit = (data) => {
    if (data) {
      const customer = {
        name: data.name,
        phone: phone,
        email: data.email
      };

      getCustomer(customer);
    }
  }

  const handleChangePhone = (value, data) => {
    setPhone({
      country_code: data.dialCode,
      number: value.toString().replace(data.dialCode, '')
    });
  }

  useEffect(() => {
    if (customer) {
      if (customer?.name) setValue('name', customer.name)
      if (customer?.email) setValue('email', customer.email)
      if (customer?.phone?.country_code && customer?.phone?.number) {
        setValue('mobilenumber', customer.phone.country_code + customer.phone.number);
        setPhone({
          country_code: customer.phone.country_code,
          number: customer.phone.number
        });
      }
    }
  }, [customer, setValue]);

  return (
    <Col md={{ span: 10, offset: 1 }} className="payment-form">
      <div className="panel">
        <div className="panel-header">
          <div className="panel-title">
            Buyer Information
          </div>
        </div>
        <div className="panel-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="space-mb-15">
              <Col lg={3} md={12} className="space-pt-7 text-label">
                <span className="form-label">Name:</span>
              </Col>
              <Col lg={9} md={12} className="text-left">
                <Controller
                  name="name"
                  control={control}
                  defaultValue={""}
                  render={({ onChange, value }) => (
                    <FormControl
                      type="text"
                      defaultValue={value}
                      placeholder="Full name as per IC / Passport"
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />
                {<span className="error-text mobile-size">{errors && errors.name ? errors.name.message : ""}</span>}
              </Col>
            </Row>
            <Row className="space-mb-15">
              <Col lg={3} md={12} className="space-pt-7 text-label">
                <span className="form-label">Email Address:</span>
              </Col>
              <Col lg={9} md={12} className="text-left">
                <Controller
                  name="email"
                  control={control}
                  defaultValue={""}
                  render={({ onChange, value }) => (
                    <FormControl
                      type="email"
                      defaultValue={value}
                      disabled={disableEmail}
                      placeholder="example@email.com"
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />
                {<span className="error-text mobile-size">{errors && errors.email ? errors.email.message : ""}</span>}
              </Col>
            </Row>
            <Row className="space-mb-15">
              <Col lg={3} md={12} className="space-pt-7 text-label">
                <span className="form-label">Mobile Number:</span>
              </Col>
              <Col lg={9} md={12} className="text-left">
                <Controller
                  name="mobilenumber"
                  control={control}
                  defaultValue={""}
                  render={({ onChange, value }) => (
                    <PhoneInput
                      country='my'
                      preferredCountries={['my']}
                      enableSearch={true}
                      value={value}
                      onChange={(value, data) => {
                        onChange(value);
                        handleChangePhone(value, data);
                      }}
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />
                {<span className="error-text mobile-size">{errors && errors.mobilenumber ? errors.mobilenumber.message : ""}</span>}
              </Col>
            </Row>
            <Row className="space-mb-15">
              <Col lg={3} md={12} className="space-pt-7 text-label">
                <span className="form-label">Payment Option:</span>
              </Col>
              <Col lg={9} md={12} className="text-left">
                <div className="payment-option-box">
                  <input
                    name="payment"
                    id="ipay88"
                    type="radio"
                    defaultChecked
                    value="Yes"
                    ref={register({ required: "This field is required" })} />
                  <label htmlFor="ipay88" className="payment-option-label">Online Payment</label>
                  <div className="mobile-hide">
                    <img src={process.env.PUBLIC_URL + '/assets/images/payment/online_payment.jpg'} alt="online payment" className="payment-option-img" />
                  </div>
                </div>
                {<span className="error-text mobile-size">{errors && errors.payment ? errors.payment.message : ""}</span>}
              </Col>
            </Row>
            <Row className="row-space-20">
              <Col className="space-pd-0 text-left">
                <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
              </Col>
              <Col className="space-pd-0 text-right">
                <Button className="btn btn-primary mobile-size" onClick={() => stepper.previous()}>Back</Button>
                <span>{' '}</span>
                <Button className="btn btn-primary mobile-size" type="submit">Continue</Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Col>
  )
}

export default PaymentForm;