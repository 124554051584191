import {
  FETCH_PASSES,
  FETCH_GROUPS,
  FETCH_CATEGORIES,
  FETCH_WORKSHOPS,
} from '../actions/productActions';

const initState = {
  passes: [],
  groups: [],
  categories: [],
  workshops: [],
}

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PASSES:
      return {
        ...state,
        passes: action.payload
      };

    case FETCH_GROUPS:
      return {
        ...state,
        groups: action.payload
      };

    case FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };

    case FETCH_WORKSHOPS:
      return {
        ...state,
        workshops: action.payload
      };

    default:
      return state;
  }
}

export default productReducer;