import { combineReducers } from 'redux';
import productReducer from './productReducer';
import orderReducer from './orderReducer';
import exchangeReducer from './exchangeReducer';
import memberReducer from './memberReducer';
import gymReducer from './gymReducer';

const rootReducer = combineReducers({
  productData: productReducer,
  orderData: orderReducer,
  exchangeData: exchangeReducer,
  memberData: memberReducer,
  gymData: gymReducer
});

export default rootReducer;