import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LayoutWithSideBar from '../components/LayoutWithSideBar';
import { restoreCart } from '../redux/actions/orderActions';
import { restoreExchangeCart } from '../redux/actions/exchangeActions';
import { restoreRenewalCart } from '../redux/actions/memberActions';

const PaymentIncomplete = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleTryAgain = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('order_id');
    const cart = JSON.parse(sessionStorage.getItem('cart'));

    if (cart.retry_path.link === "online_ticket") {
      dispatch(restoreCart(cart));
      history.push("/" + cart.retry_path.link + "/" + cart.retry_path.gym + "/" + cart.retry_path.group + "/" + cart.retry_path.type + (orderId ? ("?order_id=" + orderId) : ""));
    } else if (cart.retry_path.link === "workshop") {
      dispatch(restoreCart(cart));
      history.push("/" + cart.retry_path.link + "/" + cart.retry_path.gym + "/" + cart.retry_path.cls + (orderId ? ("?order_id=" + orderId) : ""));
    } else if (cart.retry_path.link === "competition") {
      dispatch(restoreCart(cart));
      history.push("/" + cart.retry_path.link + "/" + cart.retry_path.eventid + (orderId ? ("?order_id=" + orderId) : ""));
    } else if (cart.retry_path.link === "change_date") {
      dispatch(restoreExchangeCart(cart));
      history.push("/" + cart.retry_path.link + (orderId ? ("?order_id=" + orderId) : ""));
    } else if (cart.retry_path.link === "renew_member") {
      dispatch(restoreRenewalCart(cart));
      history.push("/" + cart.retry_path.link + (orderId ? ("?order_id=" + orderId) : ""));
    } else {
      history.push("/");
    }
  }

  return (
    <LayoutWithSideBar>
      <div style={{ margin: "100px 0" }}>
        <h2>Payment incomplete or interrupted.</h2>
        <br />
        <Button onClick={handleTryAgain}>Try again</Button>
      </div>
    </LayoutWithSideBar>
  )
}

export default PaymentIncomplete;