import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const Home = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];

  return (
    <Container>
      <Row className="justify-content-center home-title">
        <h1 className="home-title-text">{path === 'workshop' ? 'Pick the Gym For Workshop' : 'Pick Your Climbing Gym'}</h1>
      </Row>
      <Row className="space-ptb-50">
        <Col sm={12} md={6}>
          <figure className="home-figure">
            <img src="/assets/images/home/ikea-batu-kawan.png" alt="project-rock-ikea-batu-kawan" />
            <figcaption>
              <div>
                <h2>Project Rock @ <span>IKEA Batu Kawan</span></h2>
              </div>
              <a href={`/${path}/ikea-batu-kawan`}>View More</a>
            </figcaption>
          </figure>
        </Col>
        <Col sm={12} md={6}>
          <figure className="home-figure">
            <img src="/assets/images/home/gurney-plaza.png" alt="project-rock-gurney-plaza" />
            <figcaption>
              <div>
                <h2>Project Rock @ <span>Gurney Plaza</span></h2>
              </div>
              <a href={`/${path}/gurney-plaza`}>View More</a>
            </figcaption>
          </figure>
        </Col>
      </Row>
    </Container>
  )
}

export default Home;