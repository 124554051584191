import axios from "axios";
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from "./reducers/rootReducer";
import config from "../config.json";

axios.defaults.baseURL = config.axios.baseURL;
axios.defaults.withCredentials = config.axios.withCredentials;
axios.defaults.headers.common['Authorization'] = config.axios.headers.common['Authorization'];
axios.defaults.headers.post['Content-Type'] = config.axios.headers.post['Content-Type'];

export default function initializeStore() {
  return createStore(
    rootReducer,
    applyMiddleware(thunk)
  );
}