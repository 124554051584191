import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { stack as Menu } from 'react-burger-menu';

const MobileNav = (props) => {
  const { pathname } = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => setMenuOpen(state.isOpen);

  const closeMenu = () => setMenuOpen(false);

  return (
    <Menu
      right
      isOpen={menuOpen}
      onStateChange={handleStateChange}
      {...props}
    >
      <Link className={`${pathname.includes('workshop') ? 'active' : ''}`} to="/workshop" onClick={closeMenu}>Beginner's Bundle</Link>
      <a href="https://www.projectrock.com.my">Project Rock Homepage</a>
      <Link className={`${pathname.includes('online_ticket') || pathname === '/' ? 'active' : ''}`} to="/" onClick={closeMenu}>Online Tickets</Link>
      <Link className={`${pathname.includes('change_date') ? 'active' : ''}`} to="/change_date" onClick={closeMenu}>Change Visit Date</Link>
      {/* <Link to="/renew_member" onClick={closeMenu}>Membership Renewal</Link> */}
    </Menu>
  )
}

export default MobileNav;