import {
  UPDATE_CART,
  UPDATE_CUSTOMER,
  ONLINE_CHECKOUT_SUCCESS,
  ONLINE_CHECKOUT_FAILED,
  RESTORE_CART,
  VERIFY_COUPON,
  CLEAR_COUPON
} from '../actions/orderActions';

const initState = {
  cart: {
    origin: "ONLINE",
    customer: {},
    items: [],
    coupon: null,
    num_items: 0,
    subtotal: 0,
    retry_path: null
  },
  payment: null
}

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_CART:
      if (action.payload) {
        return {
          ...state,
          cart: {
            ...state.cart,
            items: [...action.payload.items],
            num_items: action.payload.num_items,
            subtotal: action.payload.subtotal,
            retry_path: action.payload.retry_path
          }
        };
      } else
        return state;

    case UPDATE_CUSTOMER:
      if (action.payload) {
        return {
          ...state,
          cart: {
            ...state.cart,
            customer: action.payload
          }
        };
      } else
        return state;

    case ONLINE_CHECKOUT_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          payment: action.payload
        };
      } else
        return state;

    case ONLINE_CHECKOUT_FAILED:
      if (action.payload) {
        return {
          ...state,
          payment: action.payload
        };
      } else
        return { ...initState };

    case RESTORE_CART:
      if (action.payload)
        return {
          cart: {
            ...action.payload
          }
        }
      else
        return state;

      case VERIFY_COUPON:
        return {
          ...state,
          cart: {
            ...state.cart,
            coupon: action.payload
          }
        };

      case CLEAR_COUPON:
        return {
          ...state,
          cart: {
            ...state.cart,
            coupon: null
          }
        }

    default:
      return state;
  }
}

export default orderReducer;