import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Row, Col, Button, Table, Modal, FormControl, InputGroup, Tooltip, Overlay } from 'react-bootstrap';
import { ArrowReturnLeft, Trash, Eraser } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGymExclusionDate } from '../redux/actions/gymActions'
import { fetchPasses, fetchGroups } from '../redux/actions/productActions';
import { updateCart, onlineCheckout, verifyCoupon, clearCoupon } from '../redux/actions/orderActions';
import { Controller, useForm } from "react-hook-form";
import Stepper from 'bs-stepper';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-input-2';
import BootstrapTable from 'react-bootstrap-table-next';
import { BsPencil, BsTrash } from 'react-icons/bs'
import { filter, find, findIndex, isEmpty, pullAt, range, reject, size } from 'lodash';
import dayjs from 'dayjs';
import axios from 'axios';
import PaymentForm from '../components/PaymentForm';
import Ipay88Form from '../components/Ipay88Form';
import LayoutWithSideBar from '../components/LayoutWithSideBar';
import { capitalize, getPassVariants, /*getGroupDesc,*/ getTicketPrice, getGroupsByVariants, getMinDays, getGroupsByAge, getAgeGrpDesc, isWeekdaysOnly } from '../lib/product';
import productData from '../data/product.json';
import config from '../config.json';

// const OPENINGDATE = '2022-06-06'; /* PRESALES TODO */
const OPENINGDATE = '2022-07-01'; /* OPENING DATE POSTPONED TO 1ST JULY 2022 */
const PRESALESMEMBERSHIPTYPE = ['e_month_member', 'f_month_member_mg', 'g_annual_member', 'h_annual_member_mg']; /* PRESALES TODO */

const Tickets = () => {
  const dispatch = useDispatch();
  const tooltipRef = useRef(null);
  const passes = useSelector(state => state.productData.passes);
  const groups = useSelector(state => state.productData.groups);
  const orders = useSelector(state => state.orderData);
  const exclusionDate = useSelector(state => state.gymData.excludeDates);
  const [stepper, setStepper] = useState();
  const [daysAhead, setDaysAhead] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState({});
  const [registeredMembers, setRegisteredMembers] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [memberMobile, setMemberMobile] = useState({});
  const [memberError, setMemberError] = useState('');
  const [memberAgeError, setMemberAgeError] = useState('');
  const [passVariants, setPassVariants] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [minimumDate, setMinimumDate] = useState(dayjs().startOf('day').toDate());
  const [customer, setCustomer] = useState({});
  const [cartCustomer, setCartCustomer] = useState({});
  const [couponCode, setCouponCode] = useState('');
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponError, setCouponError] = useState('');
  const [weekdaysOnly, setWeekdaysOnly] = useState(false);

  let ticketCount = 0;
  let subTotal = 0;
  let ticketPrices = [];
  let normalPrices = [];
  let dateRange = false;

  const { payment } = orders;

  const { gym, pass, type } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('order_id');

  const actionButton = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Fragment>
        <span className="table-button" onClick={() => handleSelectedAction("edit", row)}><BsPencil /></span>
        <span className="table-button" onClick={() => handleSelectedAction("delete", row)}><BsTrash /></span>
      </Fragment>
    )
  }

  const membersTableColumns = [{
    dataField: 'id',
    text: 'Id',
    hidden: true
  }, {
    dataField: 'sku',
    text: 'Sku',
    hidden: true
  }, {
    dataField: 'type',
    text: 'Type',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true,
    style: () => {
      return { wordWrap: "break-word" };
    }
  }, {
    dataField: 'email',
    text: 'Email',
    style: () => {
      return { wordWrap: "break-word" };
    }
  }, {
    dataField: 'dob',
    text: 'Birth Date'
  }, {
    dataField: 'mobile',
    text: 'Phone No',
    style: () => {
      return { wordWrap: "break-word" };
    }
  }, {
    dataField: 'country_code',
    text: 'Country Code',
    hidden: true
  }, {
    dataField: 'number_only',
    text: 'Number Only',
    hidden: true
  }, {
    dataField: 'action',
    text: '',
    formatter: actionButton,
    headerStyle: () => {
      return { width: "10%" };
    },
    style: () => {
      return { padding: "12px 0px" };
    }
  }];

  const {
    handleSubmit: handleSubmitTickets,
    getValues,
    setValue: setTicketsValue,
    clearErrors,
    watch: watchTickets,
    errors: errorsTickets,
    control: controlTickets
  } = useForm({
    mode: 'onChange'
  });

  const visitDate = watchTickets("visitdate");

  const {
    handleSubmit: handleSubmitMembers,
    watch: watchMembers,
    errors: errorsMembers,
    control: controlMembers
  } = useForm({
    mode: 'onChange'
  });

  const memberEmail = watchMembers('membersemail');
  const memberDob = watchMembers('membersdob');

  const handleDeleteMember = (confirm) => {
    if (confirm) {
      setRegisteredMembers(reject(registeredMembers, ['id', selectedMember.delete.id]));

      const count = getValues(selectedMember.delete.sku);
      setTicketsValue(selectedMember.delete.sku, +count - 1, { shouldValidate: true });

      setSelectedTickets({
        ...selectedTickets,
        [selectedMember.delete.sku]: +count - 1
      });

    }

    setSelectedMember({});
    setShowDeleteModal(false);
  }

  const handleSelectedAction = (action, selectedRow) => {
    let memberObject = {};
    memberObject[action] = find(registeredMembers, ['id', selectedRow.id]);
    setSelectedMember(memberObject);

  }

  const validateTicketCount = () => {
    const data = getValues();
    ticketCount = 0;
    if (data) {
      for (let key in data) {
        if (key !== 'visitdate' && key !== 'enddate') {
          clearErrors([key]);
          if (data[key]) {
            ticketCount += parseInt(data[key]);
          }
        }
      }
    }
    if (ticketCount) return true;
    else return false;
  }

  const onSubmitTickets = (data) => {
    if (data) {
      if (!data.visitdate)
        data.visitdate = dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').toDate();

      const retryPath = {
        link: "online_ticket",
        gym: gym,
        group: pass,
        type: type
      }

      let memberData = [];

      if (registeredMembers && registeredMembers.length > 0) {

        registeredMembers.forEach((registeredMember) => {
          let member = {
            type: registeredMember.type,
            sku: registeredMember.sku,
            name: registeredMember.name,
            email: registeredMember.email,
            dob: dayjs(registeredMember.dob).startOf('day').toDate(),
            phone: {
              country_code: registeredMember.country_code,
              number: registeredMember.number_only
            }
          };

          memberData.push(member);
        });
      }
      const memberNum = memberData.length;

      let items = [];
      for (let key in data) {
        if (parseInt(data[key])) {
          let variant = find(passVariants, ['sku', key]);
          let ticketPrice = find(ticketPrices, (o) => { return o[key] });
          let normalPrice = find(normalPrices, (o) => { return o[key] });

          range(memberNum ? parseInt(data[key]) : 1).forEach(() => {
            let memberIndex = findIndex(memberData, ['sku', variant.sku]);

            if (variant) {
              const parentPass = find(passes, (pass) => {
                return pass.variants.includes(variant)
              });

              items.push({
                ...variant,
                quantity: memberNum ? 1 : (data[key] ? parseInt(data[key]) : 0),
                visit_date: dayjs(data.visitdate).startOf('day').toDate(),
                type: "PASS",
                name: parentPass?._id + " " + variant.group,
                price: isEmpty(ticketPrice) ? null : ticketPrice[key],
                normalPrice: isEmpty(normalPrice) ? null : normalPrice[key],
                member: (memberIndex >= 0) ? pullAt(memberData, [memberIndex])?.[0] : null
              });
            }
          });
        }
      }

      dispatch(updateCart(items, ticketCount, subTotal, retryPath));
      stepper.next();
    }
  }

  const validateMember = async (email) => {
    let status = '';
    await axios.post('/v1/search_member', { _id: email })
      .then(response => {
        status = 'Member exist, please proceed to membership renewal!';
      })
      .catch(error => {
        if (error.response.data !== 'Member not found!')
          status = error.response.data;
      });

    return status;
  }

  const onSubmitMembers = async (data) => {
    if (data) {
      //
      // TODO: Temporary disable due to incomplete membership renewal feature
      //
      // if (data.membersemail) {
      //   const status = await validateMember(data.membersemail);
      //   setMemberError(status);
      //   if (status) return;
      // }

      const passGroups = getGroupsByVariants(passVariants, groups);
      const age = dayjs().startOf('day').diff(dayjs(data.membersdob).startOf('day'), 'year');

      let passType = "";
      let passDesc = "";
      let passSku = "";

      const filteredAge = getGroupsByAge(passGroups, age);

      // Assume one pass variant per age group
      if (filteredAge && filteredAge.length > 0) {
        passType = filteredAge[0].group;
        passDesc = getAgeGrpDesc(filteredAge[0]);
        passSku = filteredAge[0].sku;
      } else {
        setMemberAgeError("Do not have pass that match the age.");
        return;
      }

      if (passType) {
        if (!isEmpty(selectedMember)) {
          const index = findIndex(registeredMembers, ['id', selectedMember.edit.id]);

          registeredMembers[index].type = passDesc;
          registeredMembers[index].sku = passSku;
          registeredMembers[index].name = data.membersname;
          registeredMembers[index].email = data.membersemail;
          registeredMembers[index].dob = dayjs(data.membersdob).startOf('day').format('DD MMMM YYYY');
          registeredMembers[index].mobile = data.membersmobile;
          registeredMembers[index].country_code = memberMobile.country_code;
          registeredMembers[index].number_only = memberMobile.number;

          setRegisteredMembers([...registeredMembers])
        } else {
          setRegisteredMembers([
            ...registeredMembers,
            {
              id: Date.now(),
              type: passDesc,
              sku: passSku,
              name: data.membersname,
              email: data.membersemail,
              dob: dayjs(data.membersdob).startOf('day').format('DD MMMM YYYY'),
              mobile: data.membersmobile,
              country_code: memberMobile.country_code,
              number_only: memberMobile.number
            }
          ]);
        }
      }

      setSelectedMember({});
      setShowMemberModal(false);
    }
  }

  const getCustomer = (customer) => {
    setCustomer(customer)
  }

  const handleCancelUpdate = () => {
    setSelectedMember({});
    setShowMemberModal(false);
  }

  const handleChangeTickets = (e) => {
    setSelectedTickets({
      ...selectedTickets,
      [e.target.name]: e.target.value
    });
  }

  const handleCouponCode = () => {
    if (couponCode.length === 0) return;

    if (couponError.length > 0) {
      setCouponCode('');
      return;
    }

    if (couponDiscount > 0) {
      dispatch(clearCoupon());
    } else
      dispatch(verifyCoupon({ code: couponCode, cart: orders.cart }));
  }

  const handleBack = () => {
    dispatch(clearCoupon());
    stepper.previous();
  }

  const handleChangeMemberMobile = (value, data) => {
    setMemberMobile({
      country_code: data.dialCode,
      number: value.toString().replace(data.dialCode, '')
    })
  }

  useEffect(() => {
    dispatch(fetchPasses());
    dispatch(fetchGroups());
    dispatch(getGymExclusionDate(gym.replace(/-/g, ' ')));
  }, [dispatch]);

  useEffect(() => {
    setPassVariants(
      getPassVariants(
        passes,
        pass.replace(/[-]+/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, (c) => c.toUpperCase()),
        type,
        gym.replace(/-/g, " ").toLowerCase()
      )
    );
  }, [passes, pass, type]);

  useEffect(() => {
    const days = getMinDays(passVariants);

    setMinimumDate(dayjs().add(days, 'day').startOf('day').toDate());
    setWeekdaysOnly(isWeekdaysOnly(passVariants));
  }, [passVariants]);

  useEffect(() => {
    setStepper(new Stepper(document.querySelector('.bs-stepper'), { animation: true }));
  }, []);

  useEffect(() => {
    if (pass === 'pre-sales')
      setDaysAhead(dayjs(OPENINGDATE).startOf('day').diff(dayjs().startOf('day'), 'day'));
  }, [pass]); /* PRESALES TODO */

  useEffect(() => {
    if (visitDate) {
      setDaysAhead(dayjs(visitDate).startOf('day').diff(dayjs().startOf('day'), 'day'))
    }
  }, [visitDate]);

  useEffect(() => {
    if (!isEmpty(selectedMember)) {
      if (Object.keys(selectedMember).includes("edit")) {
        setShowMemberModal(true);
      } else if (Object.keys(selectedMember).includes("delete")) {
        setShowDeleteModal(true);
      }
    }
  }, [selectedMember]);

  useEffect(() => {
    if (registeredMembers.length > 0) {
      let tickets = {};
      const passGroups = getGroupsByVariants(passVariants, groups);

      passGroups &&
        passGroups.forEach((passGroup) => {
          let count = 0;
          count = filter(registeredMembers, (member) => {
            return member.sku === passGroup.sku;
          }).length;

          tickets[passGroup.sku] = count;
          setTicketsValue(passGroup.sku, count.toString(), { shouldValidate: !isEmpty(errorsTickets) });
        });

      setSelectedTickets(tickets);
    }
  }, [registeredMembers, errorsTickets, passVariants, setTicketsValue]);

  useEffect(() => {
    if (!isEmpty(customer))
      dispatch(onlineCheckout(customer));
  }, [customer, dispatch]);

  useEffect(() => {
    if (orderId) {
      if (orders.cart.items && orders.cart.items.length > 0) {

        setTicketsValue('visitdate', dayjs(orders.cart.items[0].visit_date).startOf('day').toDate());

        let selectTickets = {};
        let restoreMembers = [];
        orders.cart.items.forEach((item) => {
          selectTickets[item.sku] = selectTickets[item.sku] ?
            (parseInt(selectTickets[item.sku]) + item.quantity).toString() :
            item.quantity.toString();

          if (item.member) {
            restoreMembers.push({
              id: Date.now(),
              type: item.member.type,
              sku: item.member.sku,
              name: item.member.name,
              email: item.member.email,
              dob: dayjs(item.member.dob).startOf('day').format('DD MMMM YYYY'),
              mobile: item.member.phone.country_code + item.member.phone.number,
              country_code: item.member.phone.country_code,
              number_only: item.member.phone.number
            });
          }
        });
        setSelectedTickets(selectTickets);

        if (restoreMembers.length > 0)
          setRegisteredMembers(restoreMembers);
      }

      setCartCustomer(orders.cart.customer);
    }
  }, [orderId]);

  useEffect(() => {
    if (!couponCode || couponCode.length === 0) {
      dispatch(clearCoupon());
    }
  }, [couponCode]);

  useEffect(() => {
    const { coupon } = orders.cart;

    if (coupon) {
      let dataType = typeof coupon;
      if (dataType === 'object') {
        if (coupon.total_discount > 0) {
          setCouponError('');
          setCouponDiscount(orders.cart.coupon.total_discount);
        } else {
          setCouponError('The code is not applicable to this order');
          setCouponDiscount(0);
        }
      } else if (dataType === 'string') {
        setCouponError(orders.cart.coupon);
        setCouponDiscount(0);
      }
    } else {
      setCouponCode('');
      setCouponError('');
      setCouponDiscount(0);
    }
  }, [orders.cart.coupon]);

  useEffect(() => {
    setMemberError('');
  }, [memberEmail]);

  useEffect(() => {
    setMemberAgeError('');
  }, [memberDob]);

  if ((pass === "family-and-friends" && (type === "c_10pp" || type === 'd_10pp_mg' || type === "b_flex_daypass" || type === "i_weekday_warrior")) ||
    (pass === "pre-sales" && (type === "c_10pp" || type === 'd_10pp_mg' || type === "b_flex_daypass" || type === "g_annual_member" || type === 'h_annual_member_mg' || type === "e_month_member" || type === 'f_month_member_mg')) || /* PRESALES TODO */
    pass === "membership") {
    dateRange = true;
  }

  return (
    <LayoutWithSideBar>
      <div id="stepperForm" className="bs-stepper" style={showLoading ? { display: "none" } : {}}>
        <div className="bs-stepper-header" role="tablist">
          <div className="step" data-target="#online-ticket-form-1">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger1" aria-controls="online-ticket-form-1" disabled>
              <span className="bs-stepper-circle">1</span>
              <span className="bs-stepper-label">Buy Ticket(s)</span>
            </Button>
          </div>
          <div className="bs-stepper-line"></div>
          <div className="step" data-target="#online-ticket-form-2">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger2" aria-controls="online-ticket-form-2" disabled>
              <span className="bs-stepper-circle">2</span>
              <span className="bs-stepper-label">Order Summary</span>
            </Button>
          </div>
          <div className="bs-stepper-line"></div>
          <div className="step" data-target="#online-ticket-form-3">
            <Button className="step-trigger" role="tab" id="stepperFormTrigger3" aria-controls="online-ticket-form-3" disabled>
              <span className="bs-stepper-circle">3</span>
              <span className="bs-stepper-label">Payment</span>
            </Button>
          </div>
        </div>
        <div className="bs-stepper-content">
          <div id="online-ticket-form-1" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger1">
            <form onSubmit={handleSubmitTickets(onSubmitTickets)}>
              <div className="d-flex justify-content-center grey-panel">
                {pass === "membership" || (pass === "pre-sales" && PRESALESMEMBERSHIPTYPE.includes(type) /* PRESALES TODO */) ? (
                  <Row className="d-block grey-panel-row">
                    <span>Membership</span>
                  </Row>
                ) : (
                  <Row className="grey-panel-row">
                    <Col md={dateRange ? 2 : 5} className="date-label">
                      <span className="row-space-2">{dateRange ? "Start" : "Visit"} Date:</span>
                    </Col>
                    <Col md={dateRange ? 4 : 7} className={"date-box " + (errorsTickets && errorsTickets.visitdate ? "validation" : "")}>
                      <Controller
                        name="visitdate"
                        control={controlTickets}
                        defaultValue={dateRange ? dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').toDate() : null}
                        render={({ onChange, value }) => (
                          <DatePicker
                            dateFormat="dd MMMM yyyy"
                            selected={dateRange ? dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').toDate() : value}
                            disabled={dateRange ? true : false}
                            onChange={(value) => onChange(value)}
                            minDate={minimumDate}
                            excludeDates={!dateRange && exclusionDate ? exclusionDate.map(date => dayjs(date).toDate()) : []}
                          />
                        )}
                        rules={{ required: "This field is required" }}
                      />
                      {<span className="error-text mobile-size"><br />{errorsTickets && errorsTickets.visitdate ? errorsTickets.visitdate.message : ""}</span>}
                    </Col>
                    {dateRange &&
                      <Fragment>
                        <Col md={2} className="date-label">
                          <span className="row-space-2">End Date:</span>
                        </Col>
                        <Col md={4} className={"date-box " + (errorsTickets && errorsTickets.enddate ? "validation" : "")}>
                          <Controller
                            name="enddate"
                            control={controlTickets}
                            defaultValue={dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').add(1, (type === 'i_weekday_warrior' ? 'month' : 'year')).subtract(1, 'day').toDate()}
                            render={({ onChange, value }) => (
                              <DatePicker
                                dateFormat="dd MMMM yyyy"
                                selected={dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').add(1, (type === 'i_weekday_warrior' ? 'month' : 'year')).subtract(1, 'day').toDate()}
                                disabled={true}
                                onChange={(value) => onChange(value)}
                                minDate={minimumDate}
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </Col>
                      </Fragment>
                    }
                  </Row>
                )}
              </div>
              <Row className="form-body">
                <Table>
                  <thead>
                    <tr>
                      <th className="mobile-hide"></th>
                      <th className="align-middle">{pass === "membership" || (pass === "pre-sales" && PRESALESMEMBERSHIPTYPE.includes(type) /* PRESALES TODO */) ? "Types of Memberships" : "Types of Passes"}</th>
                      <th className="align-middle">Online Price (RM)</th>
                      <th className="align-middle">Quantity</th>
                      <th className="align-middle">Total (RM)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {passVariants.map((variant, i) => {
                      // const groupDesc = getGroupDesc(groups, variant.group);
                      const oriPrice = getTicketPrice(variant.price, 0);
                      const ticketPrice = getTicketPrice(variant.price, daysAhead);
                      const totalPrice = selectedTickets[variant.sku] ? selectedTickets[variant.sku] * ticketPrice.value : 0;

                      let opObj = {};
                      opObj[variant.sku] = oriPrice;
                      normalPrices.push(opObj);

                      let tpObj = {};
                      tpObj[variant.sku] = ticketPrice;
                      ticketPrices.push(tpObj);

                      subTotal += totalPrice;
                      return (
                        <tr key={i}>
                          <td className="mobile-hide align-middle">
                            <img
                              src={
                                variant.photos && variant.photos.length > 0 ? (
                                  config.image.imageServerURL + variant.photos[0] + config.image.imageResizeTag + config.image.imageResizeProduct
                                ) : ""
                              }
                              alt={variant.group}
                            />
                          </td>
                          <td className="align-middle">
                            {/* {groupDesc} */}
                            {variant.desc}
                          </td>
                          <td className="align-middle">
                            {oriPrice.value > ticketPrice.value &&
                              <Fragment>
                                <span className="price-discount">{oriPrice.value.toFixed(2)}</span>{' '}
                                <br className="mobile-only"></br>
                              </Fragment>
                            }
                            {ticketPrice.value.toFixed(2)}
                          </td>
                          <td className="align-middle">
                            <Controller
                              name={variant.sku}
                              control={controlTickets}
                              defaultValue={selectedTickets[variant.sku] ? selectedTickets[variant.sku].toString() : ""}
                              render={({ name, onChange, value }) => (
                                <FormControl
                                  className={(pass !== "membership" && !(pass === "pre-sales" && PRESALESMEMBERSHIPTYPE.includes(type) /* PRESALES TODO */) && errorsTickets &&
                                    ((errorsTickets.visitdate && size(errorsTickets) > 1) ||
                                      (!errorsTickets.visitdate && size(errorsTickets) > 0))) ? "validation quantity" : "quantity"}
                                  name={name}
                                  as="select"
                                  value={value}
                                  // defaultValue={value}
                                  disabled={pass === "membership" || (pass === "pre-sales" && PRESALESMEMBERSHIPTYPE.includes(type) /* PRESALES TODO */) ? true : false}
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                    handleChangeTickets(e);
                                  }}
                                >
                                  <option value=''></option>
                                  {range(variant.min_qty, 201).map((i) => {
                                    return (
                                      <option value={i.toString()} key={i}>{i}</option>
                                    )
                                  })}
                                </FormControl>
                              )}
                              rules={{ validate: validateTicketCount }}
                            />
                            {<span className="error-text mobile-size">
                              <b>{(pass !== "membership" && !(pass === "pre-sales" && PRESALESMEMBERSHIPTYPE.includes(type) /* PRESALES TODO */) && errorsTickets &&
                                ((errorsTickets.visitdate && size(errorsTickets) > 1) ||
                                  (!errorsTickets.visitdate && size(errorsTickets) > 0))) ? "Min 1 ticket" : ""}
                              </b>
                            </span>}
                          </td>
                          <td className="align-middle">
                            {totalPrice.toFixed(2)}
                          </td>
                        </tr>
                      )
                    })}
                    <tr className="grey-panel">
                      <td className="mobile-hide"></td>
                      <td colSpan='3' className="text-right">Subtotal (RM)</td>
                      <td>{subTotal.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              {(pass === "membership" || (pass === "pre-sales" && PRESALESMEMBERSHIPTYPE.includes(type) /* PRESALES TODO */)) &&
                <Fragment>
                  <Row className="form-body">
                    <BootstrapTable
                      className="member-table"
                      keyField="id"
                      bootstrap4={true}
                      striped={true}
                      caption="Register Membership(s)"
                      data={registeredMembers}
                      columns={membersTableColumns}
                      selectRow={{
                        mode: 'checkbox',
                        clickToSelect: true,
                        hideSelectColumn: true,
                      }}
                      noDataIndication={"No member found. Minimum 1 member required."}
                    />
                  </Row>
                  <Row className="center-button space-mb-30">
                    <Button className="btn btn-primary mobile-size" onClick={() => setShowMemberModal(true)}>Add</Button>
                  </Row>
                </Fragment>
              }
              <Row>
                <Col className="form-body">
                  {productData.tickets[pass][type].length > 0 &&
                    productData.tickets[pass][type].map((note, i) => {
                      return (
                        <span key={i} className="text-left block">{note}</span>
                      )
                    })
                  }
                  {weekdaysOnly &&
                    <span className="text-left block">*Check blackout dates at <a href='https://www.projectrock.com.my/blackout' target="_blank" rel="noopener noreferrer">projectrock.com.my/blackout</a></span>
                  }
                </Col>
              </Row>
              <Row className="row-space-20">
                <Col className="space-pd-0 text-left">
                  <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
                </Col>
                <Col className="space-pd-0 text-right">
                  <Button className="btn btn-primary mobile-size" type="submit">Next</Button>
                </Col>
              </Row>
            </form>
          </div>
          <div id="online-ticket-form-2" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger2">
            {orders &&
              orders.cart &&
              orders.cart.items &&
              orders.cart.items.length > 0 ? (
              <Fragment>
                <h4 className="row-space-20">Summary of your order items</h4>
                {dateRange ? (
                  <>
                    <p className="mobile-only" style={{ margin: "0" }}>
                      Start Date: {dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').format('DD MMMM YYYY')}
                    </p>
                    <p className="mobile-only">
                      End Date: {dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').add(orders.cart.items[0].duration.value, orders.cart.items[0].duration.unit.toLowerCase()).subtract(1, 'day').format('DD MMMM YYYY')}
                    </p>
                  </>
                ) : (
                  <p className="mobile-only">
                    Visit Date: {dayjs(orders.cart.items[0].visit_date).startOf('day').format('DD MMMM YYYY')}
                  </p>
                )}
                <Row>
                  <Table>
                    <thead>
                      <tr>
                        <th>Item(s)</th>
                        {dateRange ? (
                          <Fragment>
                            <th className="mobile-hide">Start Date</th>
                            <th className="mobile-hide">End Date</th>
                          </Fragment>
                        ) : (
                          <th className="mobile-hide">Visit Date</th>
                        )}
                        <th>Price (RM)</th>
                        <th>Quantity</th>
                        <th>Subtotal (RM)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.cart.items.map((item, i) => {
                        // const groupDesc = getGroupDesc(groups, item.group);
                        const oriPrice = getTicketPrice([item.normalPrice], 0);
                        const ticketPrice = getTicketPrice([item.price], daysAhead);
                        const subTotalPrice = item.quantity * ticketPrice.value;
                        return (
                          <tr key={i}>
                            {/* <td>{groupDesc}</td> */}
                            <td>{item.desc}</td>
                            {dateRange ? (
                              <Fragment>
                                <td className="mobile-hide">{dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').format('DD MMMM YYYY')}</td>
                                <td className="mobile-hide">{dayjs(pass === "pre-sales" /* PRESALES TODO */ ? OPENINGDATE : undefined).startOf('day').add(item.duration.value, item.duration.unit.toLowerCase()).subtract(1, 'day').format('DD MMMM YYYY')}</td>
                              </Fragment>
                            ) : (
                              <td className="mobile-hide">{dayjs(item.visit_date).startOf('day').format('DD MMMM YYYY')}</td>
                            )}
                            <td>
                              {oriPrice.value > ticketPrice.value &&
                                <Fragment>
                                  <span className="price-discount">{oriPrice.value.toFixed(2)}</span>{' '}
                                </Fragment>
                              }
                              {ticketPrice.value.toFixed(2)}
                            </td>
                            <td>{item.quantity}</td>
                            <td>{subTotalPrice.toFixed(2)}</td>
                          </tr>
                        )
                      })}
                      <tr className="text-bold">
                        <td className="mobile-hide"></td>
                        {dateRange &&
                          <td className="mobile-hide"></td>
                        }
                        <td colSpan='2' className="text-right">Ticket Amount</td>
                        <td></td>
                        <td>{orders.cart.subtotal?.toFixed(2)}</td>
                      </tr>
                      <tr className="text-bold">
                        <td className="mobile-hide"></td>
                        {dateRange &&
                          <td className="mobile-hide"></td>
                        }
                        <td colSpan='2' className="text-right" style={{ verticalAlign: 'middle' }}>
                          {(orders.cart.coupon && couponDiscount > 0)
                            ? 'Discount (' + orders.cart.coupon.code + ')'
                            : 'Discount'
                          }
                        </td>
                        <td colSpan='2'>
                          {(orders.cart.coupon && couponDiscount > 0)
                            ? <div style={{ verticalAlign: 'middle' }}>- {couponDiscount.toFixed(2)} <Trash size={18} style={{ float: 'right', cursor: 'pointer' }} onClick={handleCouponCode} /></div>
                            : <InputGroup size="sm">
                              <FormControl
                                ref={tooltipRef}
                                placeholder="Coupon"
                                aria-label="Coupon"
                                aria-describedby="coupon-code"
                                className="mobile-size"
                                htmlSize="10"
                                onChange={(e) => setCouponCode(e.target.value)}
                                value={couponCode}
                                onKeyPress={(key) => {
                                  if (key.code === 'Enter' || key.code === 'NumpadEnter') handleCouponCode();
                                }}
                                disabled={couponDiscount > 0}
                              />
                              <Overlay target={tooltipRef} show={couponError.length > 0} placement='top'>
                                {(props) => (
                                  <Tooltip id="mesg-tooltip" {...props}>
                                    {couponError}
                                  </Tooltip>
                                )}
                              </Overlay>
                              <InputGroup.Append>
                                <Button variant="outline-secondary" className="mobile-size" onClick={handleCouponCode}>{(couponError.length > 0) ? <Eraser /> : <ArrowReturnLeft />}</Button>
                              </InputGroup.Append>
                            </InputGroup>
                          }
                        </td>
                      </tr>
                      <tr className="text-bold">
                        <td className="mobile-hide"></td>
                        {dateRange &&
                          <td className="mobile-hide"></td>
                        }
                        <td colSpan='2' className="text-right">TOTAL (RM)</td>
                        <td></td>
                        <td>{(orders.cart.subtotal - couponDiscount)?.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row className="row-space-20">
                  <Col className="space-pd-0 text-left">
                    <Button className="btn btn-danger mobile-size" onClick={() => setShowCancelModal(true)}>Cancel</Button>
                  </Col>
                  <Col className="space-pd-0 text-right">
                    <Button className="btn btn-primary mobile-size" onClick={handleBack}>Back</Button>
                    <span>{' '}</span>
                    <Button className="btn btn-primary mobile-size" onClick={() => stepper.next()}>Buy Now</Button>
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <h3>No order found</h3>
            )
            }
          </div>
          <div id="online-ticket-form-3" role="tabpanel" className="bs-stepper-pane fade" aria-labelledby="stepperFormTrigger3">
            <PaymentForm
              stepper={stepper}
              customer={cartCustomer}
              disableEmail={false}
              getCustomer={getCustomer}
              setShowCancelModal={setShowCancelModal}
            />
          </div>
        </div>
      </div>
      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Order ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Changes you made may not be saved.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => window.location.href = `/online_ticket/${gym}/${pass}`}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showMemberModal} onHide={() => setShowMemberModal(false)} centered>
        <form onSubmit={handleSubmitMembers(onSubmitMembers)}>
          <Modal.Header closeButton>
            <Modal.Title>Memberships Registration Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="space-mb-15">
              <Col lg={3} md={12} className="space-pt-7 text-label">
                <span className="form-label">Name:</span>
              </Col>
              <Col lg={9} md={12} className="text-left">
                <Controller
                  name="membersname"
                  control={controlMembers}
                  defaultValue={!isEmpty(selectedMember) && selectedMember.edit ? selectedMember.edit.name : ""}
                  render={({ onChange, value }) => (
                    <FormControl
                      type="text"
                      defaultValue={value}
                      placeholder="Full name as per IC / Passport"
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />
                {<span className="error-text mobile-size">{errorsMembers && errorsMembers.membersname ? errorsMembers.membersname.message : ""}</span>}
              </Col>
            </Row>
            <Row className="space-mb-15">
              <Col lg={3} md={12} className="space-pt-7 text-label">
                <span className="form-label">Email:</span>
              </Col>
              <Col lg={9} md={12} className="text-left">
                <Controller
                  name="membersemail"
                  control={controlMembers}
                  defaultValue={!isEmpty(selectedMember) && selectedMember.edit ? selectedMember.edit.email : ""}
                  render={({ onChange, value }) => (
                    <FormControl
                      type="email"
                      defaultValue={value}
                      placeholder="example@email.com"
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />
                {<span className="error-text mobile-size">{errorsMembers && errorsMembers.membersemail ? errorsMembers.membersemail.message : memberError}</span>}
              </Col>
            </Row>
            <Row className="space-mb-15">
              <Col lg={3} md={12} className="space-pt-7 text-label">
                <span className="form-label">Birth Date:</span>
              </Col>
              <Col lg={9} md={12} className="text-left dob-box">
                <Controller
                  name="membersdob"
                  control={controlMembers}
                  defaultValue={!isEmpty(selectedMember) && selectedMember.edit ? dayjs(selectedMember.edit.dob).startOf('day').toDate() : null}
                  render={({ onChange, value }) => (
                    <DatePicker
                      className="form-control dob"
                      dateFormat="dd MMMM yyyy"
                      selected={value}
                      onChange={(value) => {
                        onChange(value);
                      }}
                      maxDate={dayjs().startOf('day').toDate()}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />
                {<span className="error-text mobile-size">{errorsMembers && errorsMembers.membersdob ? errorsMembers.membersdob.message : memberAgeError}</span>}
              </Col>
            </Row>
            <Row className="space-mb-15">
              <Col lg={3} md={12} className="space-pt-7 text-label">
                <span className="form-label">Mobile No:</span>
              </Col>
              <Col lg={9} md={12} className="text-left">
                <Controller
                  name="membersmobile"
                  control={controlMembers}
                  defaultValue={!isEmpty(selectedMember) && selectedMember.edit ? selectedMember.edit.mobile : ""}
                  render={({ onChange, value }) => (
                    <PhoneInput
                      country='my'
                      value={value}
                      preferredCountries={['my']}
                      enableSearch={true}
                      onChange={(value, data) => {
                        onChange(value);
                        handleChangeMemberMobile(value, data);
                      }}
                    />
                  )}
                  rules={{ required: "This field is required" }}
                />
                {<span className="error-text mobile-size">{errorsMembers && errorsMembers.membersmobile ? errorsMembers.membersmobile.message : ""}</span>}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" type="submit">
              Continue
            </Button>
            <Button variant="outline-primary" onClick={handleCancelUpdate}>
              Cancel
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you confirm to delete member?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleDeleteMember(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleDeleteMember(true)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {payment && !payment.error &&
        <Ipay88Form
          payment={payment}
          showLoading={showLoading}
          setShowLoading={setShowLoading}
        />
      }
    </LayoutWithSideBar>
  )
}

export default Tickets;