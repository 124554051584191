import React from 'react';
import { Spinner } from "react-bootstrap"

const LoadingIndicator = ({message}) => {
  return (
    <div style={{ margin: "100px 0" }}>
      <h3>{message}</h3>
      <br />
      <Spinner animation="grow" size="sm"/>
      {" "}
      <Spinner animation="grow" size="sm"/>
      {" "}
      <Spinner animation="grow" size="sm"/>
    </div>
  )
}

export default LoadingIndicator;